<template>
   <div class="four__screen" :class="{firstimg: activeBlock == 0, secondimg: activeBlock == 1, threeimg: activeBlock == 2}" id="StartGame">
      <div class="font">
         <h2>Як почати грати?<span> &nbsp; Все просто!</span></h2>
         <div class="cards">
            <div class="card" @mouseover="activeBlock = 0">
               <p>Придбайте ліцензійну копію GTAV</p>
               <div class="more__info">
                  <p>Для гри на нашому сервері необхідно мати ліцензійну копію гри GTA V.
                     Підійде будь-яка - Steam, Epic Games або Social Club.
                  </p>
                  <a href="https://store.steampowered.com/app/271590/Grand_Theft_Auto_V/" target="_blank">Придбати у Steam</a>
               </div>
            </div>
            <div class="card" @mouseover="activeBlock = 1">
               <p>Завантажте <br> та встановіть клієнт RAGE MP</p>
               <div class="more__info">
                  <p>Наш сервер працює на базі RAGE Multiplayer. Для входу на сервер
                     вам треба мати клієнт RAGE MP, який можна завантажити нижче.
                  </p>
                  <a href="https://rage.mp" target="_blank">Завантажити RAGE MP</a>
               </div>
            </div>
            <div class="card" @mouseover="activeBlock = 2" @mouseleave="textThreeBlock = 1">
               <p>Приєднайтеся до сервера SecondLife RP</p>
               <div class="more__info">
                  <p>Знайдіть наш сервер у списку в RAGE MP
                     за назвою <span>SecondLife RP</span>,
                     або можете приєднатися до нашого <span>Discord каналу</span>
                  </p>
                  <a href="https://discord.gg/TXPnVVbVNm" target="_blank" class="not__btn" @click="copyTextToClipboard()">Перейти в Discord</a>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
export default {
   name: "FourPage",
   props: {
      currentPage: {
         type: Number,
         required: true,
      },
   },
   data() {
      return {
         activeBlock: 0,
         textThreeBlock: 1
      }
   },
   methods: {
      copyTextToClipboard() {
         this.textThreeBlock = 2;
         setTimeout(() => {
            this.textThreeBlock = 3
         },1500)
         const text = 'game.secondliferp.com.ua';
         const textarea = document.createElement('textarea');
         textarea.value = text;
         textarea.setAttribute('readonly', '');
         textarea.style.position = 'absolute';
         textarea.style.left = '-9999px';
         document.body.appendChild(textarea);
         textarea.select();
         document.execCommand('copy');
         document.body.removeChild(textarea);
      }
   },
   mounted() {
      this.activeBlock = 0
   },
};
</script>
<style lang="scss">
@import './nulstyle.scss';
@import './variables.scss';
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
$mouse-device: '(hover: hover) and (pointer: fine)';
.four__screen {
   height: 100vh;
   width: 100vw;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   transition: background .5s ease;
   &.firstimg {
      transition: background .5s ease;
      background: url(../assets/img/back/slide4-1.jpg) no-repeat center center;
      background-size: cover;
      @media (max-width: 744px) {
         background: url(../assets/img/mobile/back/slide4-1.jpg) no-repeat center center;
         background-size: cover;
         overflow-x: hidden;
      }
   }
   &.secondimg {
      transition: background .5s ease;
      background: url(../assets/img/back/slide4-2.jpg) no-repeat center center;
      background-size: cover;
      @media (max-width: 744px) {
         background: url(../assets/img/mobile/back/slide4-2.jpg) no-repeat center center;
         background-size: cover;
         overflow-x: hidden;
      }
   }
   &.threeimg {
      transition: background .5s ease;
      background: url(../assets/img/back/slide4-3.jpg) no-repeat center center;
      background-size: cover;
      @media (max-width: 744px) {
         background: url(../assets/img/mobile/back/slide4-3.jpg) no-repeat center center;
         background-size: cover;
         overflow-x: hidden;
      }
   }

   & > .font {
      z-index: 1;
      width: get-vh(1600px);
      height: get-vh(720px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 1440px) {
         width: get-vh(1280px);
         height: get-vh(576px);
      }
      @media (max-width: 1180px) {
         width: get-vh(1024px);
         height: get-vh(472px);
      }
      @media (max-width: 960px) {
         width: get-vh(830px);
         height: get-vh(382px);
      }
      @media (max-width: 744px) {
         width: 100%;
         height: auto;
      }
      & > h2 {
         display: flex;
         align-items: center;
         justify-content: center;
         font-weight: 700;
         font-size: get-vh(96px);
         line-height: 125%;
         letter-spacing: -0.03em;
         text-shadow: 0px get-vh(2px) get-vh(32px) rgba(0, 0, 0, 0.2);
         & > span {
            color: #ffb800;
         }
         @media (max-width: 1440px) {
            font-size: get-vh(76px);
            line-height: 125%;
         }
         @media (max-width: 1180px) {
            font-size: get-vh(62px);
            line-height: 125%;
         }
         @media (max-width: 960px) {
            font-size: get-vh(50px);
            line-height: 125%;
         }
         @media (max-width: 744px) {
            font-size: get-vh(54px);
            line-height: 120%;
            text-align: center;
            display: flex;
            flex-direction: column;
            width: get-vh(528px);
            margin-left: auto;
            margin-right: auto;
         }
         @media (max-width: 400px) {
            font-size: get-vh(36px);
            line-height: 120%;
            text-align: center;
            display: flex;
            flex-direction: column;
            width: get-vh(352px);
            margin-left: auto;
            margin-right: auto;
         }
      }
      & > .cards {
         margin-top: get-vh(36px);
         display: flex;
         justify-content: space-between;
         height: get-vh(600px);
         width: 100%;
         @media (max-width: 1440px) {
            height: get-vh(480px);
         }
         @media (max-width: 1180px) {
            height: get-vh(394px);
         }
         @media (max-width: 960px) {
            height: get-vh(320px);
         }
         @media (max-width: 744px) {
            height: get-vh(720px);
            overflow-x: scroll;
            &.cards::-webkit-scrollbar {
               width: 0;
               height: 0;
            }
            &.cards {
               scrollbar-width: none;
            }
         }
         @media (max-width: 400px) {
            height: get-vh(480px);
         }
         & > .card {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            border-radius: get-vh(36px);
            width: get-vh(512px);
            height: 100%;
            overflow: hidden;
            transition: background 0.3s ease-in-out;
            flex-shrink: 0;
            @media (max-width: 1440px) {
               width: get-vh(410px);
            }
            @media (max-width: 1180px) {
               width: get-vh(326px);
            }
            @media (max-width: 960px) {
               width: get-vh(264px);
            }
            @media (max-width: 744px) {
               height: 100%;
               width: get-vh(450px);
               &:nth-child(1) {
                  margin-left: get-vh(60px);
                  @media (max-width: 576px) { 
                     margin-left: get-vh(40px);
                  }
               }
               &:not(:first-child) {
                  margin-left: get-vh(36px);
               } 
               &:last-child {
                  margin-right: get-vh(60px);
                  @media (max-width: 576px) { 
                     margin-left: get-vh(40px);
                  }
               }
            }
            @media (max-width: 400px) {
               height: 100%;
               width: get-vh(300px);
               &:nth-child(1) {
                  margin-left: get-vh(24px);
               }
               &:not(:first-child) {
                  margin-left: get-vh(16px);
               } 
               &:last-child {
                  margin-right: get-vh(30px);
               }
            }
            & > .first {
               width: 100%;
               height: 100%;
               transition: opacity 0.3s ease-in-out;
            }
            &.card:hover .first {
               @media #{$mouse-device} {
                  opacity: 1;
               }
            }
            &:nth-child(1) {
               background: url("../assets/img/slide4/pic1.jpg");
               background-size: cover;
               @media (max-width: 744px) {
                  background: url(../assets/img/mobile/slide4/card1.webp) no-repeat center center;
                  background-size: cover;
               }
               &:hover {
                  @media #{$mouse-device} {
                     background: url("../assets/img/slide4/pic1blur.jpg");
                     background-size: cover;
                  }
               }
            }
            &:nth-child(2) {
               background: url("../assets/img/slide4/pic2.jpg");
               background-size: cover;
               @media (max-width: 744px) {
                  background: url(../assets/img/mobile/slide4/card2.webp) no-repeat center center;
                  background-size: cover;
               }
               &:hover {
                  @media #{$mouse-device} {
                     background: url("../assets/img/slide4/pic2blur.jpg");
                     background-size: cover;
                  }
               }
            }
            &:nth-child(3) {
               background: url("../assets/img/slide4/pic3.jpg");
               background-size: cover;
               @media (max-width: 744px) {
                  background: url(../assets/img/mobile/slide4/card3.webp) no-repeat center center;
                  background-size: cover;
               }
               &:hover {
                  @media #{$mouse-device} {
                     background: url("../assets/img/slide4/pic3blur.jpg");
                     background-size: cover;
                  }
               }
               
            }
            & > p {
               position: absolute;
               bottom: get-vh(48px);
               width: get-vh(416px);
               height: get-vh(160px);
               font-weight: 700;
               font-size: get-vh(48px);
               line-height: 120%;
               letter-spacing: -0.03em;
               color: #ffffff;
               transition: 0.3s ease-in-out;
               @media (max-width: 1440px) {
                  font-size: get-vh(38px);
                  line-height: 120%;
                  bottom: get-vh(38px);
                  width: get-vh(352px);
                  height: get-vh(128px);
               }
               @media (max-width: 1180px) {
                  font-size: get-vh(30px);
                  line-height: 120%;
                  bottom: get-vh(32px);
                  width: get-vh(272px);
                  height: get-vh(104px);
               }
               @media (max-width: 960px) {
                  font-size: get-vh(24px);
                  line-height: 120%;
                  bottom: get-vh(26px);
                  width: get-vh(220px);
                  height: get-vh(84px);
               }
               @media (max-width: 744px) {
                  font-size: get-vh(45px);
                  line-height: 110%;
                  bottom: get-vh(264px);
                  width: get-vh(370px);
                  height: get-vh(132px);
               }
               @media (max-width: 400px) {
                  font-size: get-vh(30px);
                  line-height: 110%;
                  bottom: get-vh(176px);
                  width: get-vh(247px);
                  height: get-vh(88px);
               }
            }
            &.card:hover > p {
               @media #{$mouse-device} {
                  bottom: get-vh(362px);
                  @media (max-width: 1440px) {
                     bottom: get-vh(282px);
                  }
                  @media (max-width: 1180px) {
                     bottom: get-vh(226px);
                  }
                  @media (max-width: 960px) {
                     bottom: get-vh(183px);
                  }
               }
            }
            & > .more__info {
               width: get-vh(416px);
               height: get-vh(282px);
               position: absolute;
               opacity: 0;
               bottom: -100px;
               display: flex;
               flex-direction: column;
               justify-content: space-between;
               transition: 0.3s ease-in-out;
               @media (max-width: 1440px) {
                  width: get-vh(352px);
                  height: get-vh(225px);
               }
               @media (max-width: 1180px) {
                  width: get-vh(272px);
                  height: get-vh(180px);
               }
               @media (max-width: 960px) {
                  width: get-vh(224px);
                  height: get-vh(146px);
               }
               @media (max-width: 744px) {
                  opacity: 1;
                  bottom: get-vh(30px);
                  width: get-vh(370px);
                  height: get-vh(204px);
               }
               @media (max-width: 400px) {
                  opacity: 1;
                  bottom: get-vh(28px);
                  width: get-vh(252px);
                  height: get-vh(130px);
               }
               & > p {
                  width: get-vh(416px);
                  height: get-vh(192px);
                  font-weight: 500;
                  font-size: get-vh(28px);
                  line-height: 125%;
                  letter-spacing: -0.03em;
                  color: #ffffff;
                  & > span {
                     font-weight: 600;
                     color: #ffb800;
                     &:nth-child(2) {
                        color: #2697ff;
                     }
                  }
                  @media (max-width: 1440px) {
                     line-height: 125%;
                     font-size: get-vh(22px);
                     width: 100%;
                  }
                  @media (max-width: 1180px) {
                     line-height: 125%;
                     font-size: get-vh(18px);
                     width: 100%;
                  }
                  @media (max-width: 960px) {
                     line-height: 125%;
                     font-size: get-vh(14.5px);
                     width: 100%;
                  }
                  @media (max-width: 744px) {
                     font-weight: 500;
                     font-size: get-vh(21px);
                     line-height: 130%;
                     width: get-vh(372px);
                  }
                  @media (max-width: 400px) {
                     font-weight: 500;
                     font-size: get-vh(14px);
                     line-height: 130%;
                     width: get-vh(252px);
                  }
               }
               & > a {
                  cursor: pointer;
                  width: get-vh(416px);
                  height: get-vh(78px);
                  background: rgba(255, 255, 255, 0.2);
                  border-radius: get-vh(24px);
                  font-weight: 600;
                  font-size: get-vh(30px);
                  line-height: 125%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  letter-spacing: -0.02em;
                  color: #ffffff;
                  flex-shrink: 0;
                  transition: background .25s ease, color .25s ease;
                  &:hover {
                     @media #{$mouse-device} {
                        background: #ffffff;
                        color: #000000;
                     }
                  }
                  @media (max-width: 1440px) {
                     width: 100%;
                     height: get-vh(62px);
                     border-radius: get-vh(19px);
                     font-size: get-vh(24px);
                     line-height: 125%;
                  }
                  @media (max-width: 1180px) {
                     height: get-vh(50px);
                     border-radius: get-vh(16px);
                     font-size: get-vh(20px);
                     line-height: 125%;
                     width: 100%;
                  }
                  @media (max-width: 960px) {
                     height: get-vh(40px);
                     border-radius: get-vh(13px);
                     font-size: get-vh(16px);
                     line-height: 125%;
                     width: 100%;
                  }
                  @media (max-width: 744px) {
                     width: 100%;
                     height: get-vh(72px);
                     font-size: get-vh(27px);
                     line-height: 120%;
                     border-radius: get-vh(24px);
                  }
                  @media (max-width: 400px) {
                     width: 100%;
                     height: get-vh(48px);
                     font-size: get-vh(18px);
                     line-height: 120%;
                     border-radius: get-vh(16px);
                  }
               }
               & > .not__btn {
                  cursor: pointer;
                  width: get-vh(416px);
                  height: get-vh(78px);
                  background: rgba(255, 255, 255, 0.2);
                  border-radius: get-vh(24px);
                  font-weight: 600;
                  font-size: get-vh(30px);
                  line-height: 125%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  letter-spacing: -0.02em;
                  color: #ffffff;
                  flex-shrink: 0;
                  transition: background .25s ease, color .25s ease;
                  &:hover {
                     @media #{$mouse-device} {
                        background: #ffffff;
                        color: #000000;
                     }
                  }
                  @media (max-width: 1440px) {
                     width: 100%;
                     height: get-vh(62px);
                     border-radius: get-vh(19px);
                     font-size: get-vh(24px);
                     line-height: 125%;
                  }
                  @media (max-width: 1180px) {
                     height: get-vh(50px);
                     border-radius: get-vh(16px);
                     font-size: get-vh(20px);
                     line-height: 125%;
                     width: 100%;
                  }
                  @media (max-width: 960px) {
                     height: get-vh(40px);
                     border-radius: get-vh(13px);
                     font-size: get-vh(16px);
                     line-height: 125%;
                     width: 100%;
                  }
                  @media (max-width: 744px) {
                     width: 100%;
                     height: get-vh(72px);
                     font-size: get-vh(27px);
                     line-height: 120%;
                     border-radius: get-vh(24px);
                  }
                  @media (max-width: 400px) {
                     width: 100%;
                     height: get-vh(48px);
                     font-size: get-vh(18px);
                     line-height: 120%;
                     border-radius: get-vh(16px);
                  }
               }
            }
            &.card:hover > .more__info {
               @media #{$mouse-device} {
                  opacity: 1;
                  bottom: get-vh(48px);
                  transition: 0.3s ease-in-out;
                  @media (max-width: 1440px) {
                     bottom: get-vh(38px);
                  }
                  @media (max-width: 1180px) {
                     bottom: get-vh(30px);
                  }
               }
            }
         }
      }
   }
}

</style>