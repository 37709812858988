<template>
   <div class="five__screen" id="Faq">
      <header>
         <h2>FAQ</h2>
      </header>
      <main>
         <div class="container__info" @mouseover="handleMouseOver(true)" @mouseleave="handleMouseOver(false)">
            <div class="item" v-for="(info, index) in information" :key="index" :class="{ active: activeIndex === index }" 
               @click="() => {
                  handleMouseOver(false)
                  activeIndex = (activeIndex === index) ? null : index;
               }">
               <b><img src="../assets/img/add.svg" alt="add" class="plus" draggable="false"
                :class="{ rotation: activeIndex === index }"> {{ info.header }}</b>
               <p v-if="activeIndex === index">{{ info.content }}</p>
            </div>
         </div>
      </main>
      <footer>
         <div class="foot__container" v-if="checkSizeWidth">
            <div class="logo__text">
               <img src="../../src/assets/img/logoMini.png" alt="Logo" draggable="false">
               <div class="mini__text">
                  © 2024 SecondLife RP. Всі права захищені. <br>
                  <span>
                     SecondLife RolePlay не підтримується та не пов'язана з Take-Two,
                     Rockstar North Interactive або іншими правовласниками.
                     Усі використані товарні знаки належать їх відповідним власникам.
                  </span>
               </div>
            </div>
            <div class="maney__words">
               <div class="item"><router-link to="/politika_konfidenchiynosti">Політика конфіденційності</router-link></div>
               <div class="item"><router-link to="/dogovir/zrazok_publichnoho_dohovoru_oferty">Користувацька угода</router-link></div>
               <div class="item"><router-link to="/pravila_koristyvannya_saitom">Правила користування сайтом</router-link></div>
            </div>
            <div class="few__text" v-if="checkSizeWidth">
               <div class="item"><a href="https://forum.secondliferp.com.ua/index.php" target="_blank">Форум</a></div>
               <div class="item" @click="$emit('update:current-page', -1); $emit('update:active-header', 1)">Донат</div>
               <div class="item"><a href="mailto:secondlifeukraine@gmail.com">E-mail</a></div>
            </div>
            <div class="media" v-if="checkSizeWidth">
               <div class="head">Наші соцмережі:</div>
               <ul class="social-icons">
                  <li><a href="https://discord.gg/TXPnVVbVNm" target="_blank"><img src="../../src/assets/img/discrod.svg" alt="discrod" draggable="false"></a></li>
                  <li><a href="https://t.me/SecondLifeRP" target="_blank"><img src="../../src/assets/img/telegram.svg" alt="telegram" draggable="false"></a></li>
                  <li><a href="https://www.youtube.com/@SecondLifeRP_UA" target="_blank"><img src="../../src/assets/img/youtube.svg" alt="yt" draggable="false"></a></li>
                  <li><a href="https://www.instagram.com/secondliferp_ua" target="_blank"><img src="../../src/assets/img/instagram.svg" alt="inst" draggable="false"></a></li>
                  <li><a href="https://www.tiktok.com/@secondliferp_ua" target="_blank"><img src="../../src/assets/img/tiktok.svg" alt="tiktok" draggable="false"></a></li>
               </ul>
            </div>
         </div>
         <div class="foot__containermin" v-if="!checkSizeWidth">
            <img src="../../src/assets/img/logoMini.png" alt="Logo" draggable="false" class="logo__text">
            <div class="few__text">
               <div class="item"><a href="https://forum.secondliferp.com.ua/index.php" target="_blank">Форум</a></div> <div class="sep"></div>
               <div class="item"><a href="mailto:secondlifeukraine@gmail.com">E-mail</a></div> <div class="sep"></div>
               <div class="item" @click="$emit('update:current-page', -1); $emit('update:active-header', 1)">Донат</div>
            </div>
            <div class="media">
               <ul class="social-icons">
                  <li><a href="https://discord.gg/TXPnVVbVNm" target="_blank"><img src="../../src/assets/img/discrod.svg" alt="discrod" draggable="false"></a></li>
                  <li><a href="https://t.me/SecondLifeRP" target="_blank"><img src="../../src/assets/img/telegram.svg" alt="telegram" draggable="false"></a></li>
                  <li><a href="https://www.youtube.com/@SecondLifeRP_UA" target="_blank"><img src="../../src/assets/img/youtube.svg" alt="yt" draggable="false"></a></li>
                  <li><a href="https://www.instagram.com/secondliferp_ua" target="_blank"><img src="../../src/assets/img/instagram.svg" alt="inst" draggable="false"></a></li>
                  <li><a href="https://www.tiktok.com/@secondliferp_ua" target="_blank"><img src="../../src/assets/img/tiktok.svg" alt="tiktok" draggable="false"></a></li>
               </ul>
            </div>
            <div class="maney__words">
               <div class="item"><router-link to="/politika_konfidenchiynosti">Політика конфіденційності</router-link></div>
               <div class="item"><router-link to="/dogovir/zrazok_publichnoho_dohovoru_oferty">Користувацька угода</router-link></div>
               <div class="item"><router-link to="/pravila_koristyvannya_saitom">Правила користування сайтом</router-link></div>
            </div>
            <div class="mini__textlover">
               © 2024 SecondLife RP. Всі права захищені. <br>
               <span>
                  SecondLife RolePlay не підтримується та не пов'язана з Take-Two,
                  Rockstar North Interactive або іншими правовласниками.
                  Усі використані товарні знаки належать їх відповідним власникам.
               </span>
            </div>
         </div>
      </footer>
   </div>
   </template>
   <script>
   export default {
      name: "FivePage",
      props: {
         currentPage: {
            type: Number,
            required: true,
         },
      },
      data() {
         return {
            information: [
               {header: 'Чи є на сервері правила, і де їх можна знайти?', content: 'Авжеж є! Всі гравці мають дотримуватися правил сервера. Вони розбиті на окремі категорії та доволі часто оновлюються, аби бути актуальними і створювати максимально комфортний і зрозумілий для гравців геймплей. Знайти їх можна у Discord. Там можна слідкувати за ними, увімкнувши сповіщення на окремі категорії, а також і обговорювати їх у спеціальній гілці. Пропонуйте свої зміни - ми обов’язково врахуємо думку спільноти!'},
               {header: 'Чи є у вас реферальна система? Як запросити друга або отримати запрошення від нього?', content: 'Звісно є! Ви можете отримати бонус при старті від ваших друзів та медійних осіб, а також можете і самі запросити когось, отримавши бонус, який зростає з кожним п’ятим запрошеним гравцем. У вкладці “Бонуси” головного меню є підменю “Реферали та промокоди”, де ви можете скопіювати реферальний промокод або ввести інший. Також адміністрація часто роздає додаткові промокоди - не пропустіть!'},
               {header: 'Яка мова спілкування та взаємодії між гравцями?', content: 'Ми - українці. Ми поважаємо нашу державу, нашу мову і наш народ. І так само поважаємо наших гравців. Тому мова спілкування на сервері - виключно українська. “Суржик” та інші діалекти/говірки також вітаються, адже не всі ми ідеальні, і після стількох років російського впливу перехід на чисту українську для когось може бути нелегкою задачею. Мова окупанта суворо заборонена.'},
               {header: 'Який у вас рівень відігравання role-play?', content: 'Сервер SecondLife RP орієнтований на рівень Medium RP - це симуляція реального життя з деякими вагомими спрощеннями задля більш комфортного геймплею. Тобто ми не вимагаємо повного реалізму та опису кожного найменшого кроку, але бажано намагатися тримати деяку серйозність та виконувати розгорнуте відігравання дій у складних ситуаціях.'},
               {header: 'Гра на сервері безкоштовна? Чи необхідно за щось платити?', content: 'Повністю безкоштовна! Вам не потрібно платити для того, аби грати на нашому сервері. Але окрім цього ми даємо усім бажаючим додаткові преміальні бонуси за спеціальну ігрову валюту. Донат ніяк не впливає на основний геймплей - абсолютно всі стандартні ігрові елементи доступні і без нього, а також відсутні будь-які софт-блоки/пейволли та мінімізований грінд. Донат лише відкриває доступ до унікальних автомобілів, кастомних інтер’єрів будинків, стильного одягу та деяких інших преміальних послуг.'},
               {header: 'Я знайшов баг! Як мені про нього повідомити?', content: 'Хоч ми і професійні розробники, але розмір гри є колосальним і, на жаль, інколи деякі проблеми просто непомітні для нас. Якщо ви знайшли якийсь баг - повідомте нам у Discord в розділі “Баги та глітчі”, або в глобальному меню у вкладці “Інформація” - “Допомога” прямо у грі, або ж на пошту за допомогою кнопки “Написати нам” внизу сторінки. Обов’язково опишіть поетапно ваші дії для виникнення багу, а також бажано додайте скріншоти та запис екрану з детальною демонстрацією проблеми.'},
               {header: 'Я хочу об’єднатися зі своїми друзями у фракцію. Чи можна це зробити?', content: 'На нашому сервері є купа фракцій за професіями - державна, військова, правоохоронна, медична, СБУ та журналісти. Кожна з фракцій виконує свою роль і забезпечує повноцінне життя громадян. У нас також є і "умовно злочинні" угрупування - банди. Вони охороняють свою територію, "кришують" райони. Існує 4 банди, які контролюють свої великі регіони - Київська, Харківська, Дніпровська та Львівська.'},
               {header: 'Як вирішити проблему з ігровим процесом або конфліктом з гравцем/адміністратором?', content: 'Писати нам прямо у грі (глобальному меню, вкладка “Інформація” - “Допомога”) або у Discord в розділі “Проблеми/суперечки”, або ж на пошту за допомогою кнопки “Написати нам” внизу сторінки. Опишіть детально вашу проблему, вказуючи причини виникнення, події, що передували їй, дату, час, місце події, через яку виникла проблема/суперечка. Додайте скріншоти та запис екрану з детальною демонстрацією проблеми, якщо такі є. Не дублюйте запити, які ви вже раніше писали, якщо ви не отримали на них відповідь - ми про вас не забули, очікуйте відповіді, ми обов’язково допоможемо.'},
               {header: 'Чи є у вас якісь цікаві розваги? Хочу взяти участь у якихось івентах!', content: 'Ми робимо все можливе, аби вам було цікаво грати на нашому сервері, тому окрім звичайного role-play у нас також є деякі розваги. На старті будуть доступні арена, автоматичні івенти та казино. Також адміністрація буде інколи оголошувати великі івенти на свята та визначні події (наприклад, День смерті путіна). Пізніше ми додамо ще щось цікавеньке - слідкуйте за новинами у соцмережах!'},
               {header: 'Хочу запропонувати круту ідею або щось ще, бо на сервері цього немає/не працює.', content: 'Круто! Ми завжди відкриті до пропозицій і чекаємо ваших повідомлень з ідеями щодо нововведень. Надсилайте їх у Discord в розділ “Ідеї та пропозиції” або на пошту за допомогою кнопки “Написати нам” внизу сторінки. Якщо ваша ідея нам сподобається і ми реалізуємо її, то ви будете обов’язково відмічені у новині про апдейт як автор задумки.' },
            ],
            activeIndex: null,
            checkForScroll: false,
            checkSizeWidth: true
         }
      },
      methods: {
         handleMouseOver(type) {
            if(this.activeIndex === null) return;
            this.$emit('update:check-for-scroll', type);
         },
         WidthUpdate() {
            if (window.innerWidth <= 744) this.checkSizeWidth = false;
            else this.checkSizeWidth = true
         },
      },
      watch: {
         currentPage(newValue) {
            if (newValue !== 4) {
               this.activeIndex = null;
            }
         },
      },
      created() {
         window.addEventListener("resize", this.WidthUpdate);
      },
      mounted() {
         this.WidthUpdate();
      },
   };
   </script>
   <style lang="scss">
   @import './nulstyle.scss';
   @import './variables.scss';
   @import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
   .five__screen {
      overflow: hidden;
      height: 100vh;
      width: 100vw;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: url(../assets/img/back/slide5.jpg) no-repeat center center;
      background-size: cover;
      @media (max-width: 744px) {
         display: flex;
         align-items: center;
         justify-content: center;
         flex-direction: column;
         height: auto;
         background: url(../assets/img/mobile/back/slide5.jpg) no-repeat center center;
         background-size: cover;
         overflow-x: hidden;
      }
      & > header {
         max-width: get-vh(1920px);
         width: calc(100vw - #{get-vh(192px)});
         height: get-vh(200px);
         display: flex;
         align-items: center;
         justify-content: flex-start;
         padding-left: get-vh(12px);
         @media (max-width: 960px) { 
            display: flex;
            align-items: center;
            justify-content: center;
         }
         & > h2 {
            font-weight: 700;
            font-size: get-vh(64px);
            line-height: 100%;
            letter-spacing: -0.03em;
            color: #FFFFFF;
            @media (max-width: 960px) { 
               z-index: 2;
               margin-top: get-vh(140px);
            }
         }
      }
      & > main {
         flex: 1 1 auto;
         max-width: get-vh(1920px);
         width: calc(100vw - #{get-vh(192px)});
         @media (max-width: 744px) { 
            width: calc(100vw - #{get-vh(64px)});
         }
         @media (max-width: 400px) { 
            width: calc(100vw - #{get-vh(24px)});
         }
         & > .container__info {
            z-index: 2;
            width: 100%;
            height: get-vh(710px);
            gap: get-vh(22px);
            overflow-y: scroll;
            &.container__info::-webkit-scrollbar {
               width: 0;
               height: 0;
            }
            &.container__info {
               scrollbar-width: none;
            }
            @media (max-width: 960px) { 
               margin-top: get-vh(46px);
               height: auto;
            }
            & > .item {
               cursor: pointer;
               padding: get-vh(12px) 0 get-vh(12px) get-vh(15px);
               border-radius: get-vh(24px);
               width: 100%;
               display: flex;
               flex-direction: column;
               justify-content: start;
               transition: padding .25s ease, background .5s ease;
               &:last-child {
                  margin-bottom: get-vh(30px);
               }
               & > b {
                  font-weight: 600;
                  font-size: get-vh(28px);
                  line-height: 120%;
                  letter-spacing: -0.03em;
                  @media (max-width: 960px) { 
                     font-weight: 600;
                     font-size: get-vh(24px);
                     line-height: 120%;
                     letter-spacing: -0.03em;
                  }
                  & > .plus {
                     cursor: pointer;
                     width: get-vh(30px);
                     height: get-vh(36px);
                     margin-right: get-vh(16px);
                     transition: transform .5s ease;
                     &.rotation {
                        transform: rotate(135deg);
                     }
                     @media (max-width: 960px) { 
                        width: get-vh(24px);
                        height: get-vh(30px);
                        margin-right: get-vh(12px);
                     }
                  }
               }
               & > p {
                  font-style: normal;
                  font-weight: 500;
                  font-size: get-vh(24px);
                  line-height: 130%;
                  letter-spacing: -0.01em;
                  overflow: hidden;
                  margin-left: get-vh(6px);
                  margin-top: get-vh(10px);
                  opacity: 0;
                  animation: popacity .35s forwards .25s;
                  @keyframes popacity {
                     0% {
                        opacity: 0;
                     }
                     100% {
                        opacity: 1;
                     }
                  }
               }
            }
            & > .active {
               background: rgba(0, 0, 0, 0.6);
               border-radius: get-vh(24px);
               padding: get-vh(12px) get-vh(20px) get-vh(21px) get-vh(15px);
               transition: padding .25s ease, background .5s ease, box-shadow .5s ease;
               &.item:last-child.active {
                  margin-bottom: get-vh(40px);
               }
            }
         }
      }
      & > footer {
         position: absolute;
         bottom: 0;
         z-index: 2;
         width: 100%;
         height: get-vh(200px);
         background: rgba(0, 0, 0, 0.6);
         backdrop-filter: blur(8px);
         display: flex;
         align-items: center;
         justify-content: center;
         @media (max-width: 1440px) {
            height: get-vh(160px);
         }
         @media (max-width: 1180px) {
            height: get-vh(132px);
         }
         @media (max-width: 960px) {
            height: get-vh(108px);
         }
         & > .foot__container {
            height: 100%;
            max-width: get-vh(1920px);
            width: calc(100vw - #{get-vh(192px)});
            display: flex;
            align-items: center;
            justify-content: space-between;
            & > .logo__text {
               width: get-vh(635px);
               height: get-vh(80px);
               display: flex;
               justify-content: space-between;
               @media (max-width: 1440px) {
                  width: get-vh(508px);
                  height: get-vh(64px);
               }
               @media (max-width: 1180px) {
                  width: get-vh(416px);
                  height: get-vh(46px);
               }
               @media (max-width: 960px) {
                  width: get-vh(336px);
                  height: get-vh(37px);
               }
               @media (max-width: 840px) {
                  width: get-vh(294px);
                  height: get-vh(32px);
               }
               & > img {
                  width: 35%;
                  height: 100%;
               }
               & > .mini__text {
                  width: 61%;
                  font-weight: 600;
                  font-size: get-vh(16px);
                  line-height: 120%;
                  letter-spacing: -0.02em;
                  color: rgba(255, 255, 255, 0.6);
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  & > span {
                     margin-top: get-vh(8px);
                     display: flex;
                     width: 100%;
                     font-weight: 500;
                     font-size: get-vh(12px);
                     line-height: 100%;
                     letter-spacing: -0.07em;
                  } 
                  @media (max-width: 1440px) {
                     width: 68%;
                     font-weight: 600;
                     font-size: get-vh(13px);
                     line-height: 120%;
                     letter-spacing: -0.02em;
                     color: rgba(255, 255, 255, 0.6);
                     & > span {
                        font-weight: 500;
                        font-size: get-vh(10px);
                        line-height: 100%;
                        letter-spacing: -0.07em;
                     } 
                  }
                  @media (max-width: 1180px) {
                     width: 68%;
                     font-weight: 600;
                     font-size: get-vh(10px);
                     line-height: 120%;
                     letter-spacing: -0.02em;
                     color: rgba(255, 255, 255, 0.6);
                     & > span {
                        margin-top: get-vh(4px);
                        font-weight: 500;
                        font-size: get-vh(8px);
                        line-height: 100%;
                        letter-spacing: -0.07em;
                     } 
                  }
                  @media (max-width: 960px) {
                     width: 68%;
                     font-weight: 600;
                     font-size: get-vh(8px);
                     line-height: 120%;
                     letter-spacing: -0.02em;
                     color: rgba(255, 255, 255, 0.6);
                     & > span {
                        margin-top: get-vh(3.5px);
                        font-weight: 500;
                        font-size: get-vh(6.5px);
                        line-height: 100%;
                        letter-spacing: -0.07em;
                     } 
                  }
                  @media (max-width: 840px) {
                     width: 68%;
                     font-weight: 600;
                     font-size: get-vh(7px);
                     line-height: 120%;
                     letter-spacing: -0.02em;
                     color: rgba(255, 255, 255, 0.6);
                     & > span {
                        margin-top: get-vh(3.5px);
                        font-weight: 500;
                        font-size: get-vh(5.6px);
                        line-height: 100%;
                        letter-spacing: -0.07em;
                     } 
                  }
               }
            }
            & > .maney__words {
               width: get-vh(330px);
               height: get-vh(76px);
               display: flex;
               flex-direction: column;
               justify-content: space-between;
               & > .item , a{
                  cursor: pointer;
                  width: 100%;
                  font-weight: 600;
                  font-size: get-vh(20px);
                  line-height: 100%;
                  letter-spacing: -0.03em;  
                  color: #FFFFFF;
               }
               @media (max-width: 1440px) {
                  width: get-vh(278px);
                  height: get-vh(60px);
                  & > .item {
                     width: 100%;
                     font-weight: 600;
                     font-size: get-vh(16px);
                     line-height: 100%;
                     letter-spacing: -0.03em;  
                     color: #FFFFFF;
                  }
               }
               @media (max-width: 1180px) {
                  width: get-vh(278px);
                  height: get-vh(48px);
                  & > .item {
                     width: 100%;
                     font-weight: 600;
                     font-size: get-vh(13px);
                     line-height: 100%;
                     letter-spacing: -0.03em;  
                     color: #FFFFFF;
                  }
               }
               @media (max-width: 960px) {
                  width: get-vh(238px);
                  height: get-vh(38px);
                  & > .item {
                     width: 100%;
                     font-weight: 600;
                     font-size: get-vh(10.5px);
                     line-height: 100%;
                     letter-spacing: -0.03em;  
                     color: #FFFFFF;
                  }
               }
               @media (max-width: 840px) {
                  width: get-vh(126px);
                  height: get-vh(33px);
                  & > .item {
                     width: 100%;
                     font-weight: 600;
                     font-size: get-vh(9px);
                     line-height: 100%;
                     letter-spacing: -0.03em;  
                     color: #FFFFFF;
                  }
               }
            }
            & > .few__text {
               width: get-vh(71px);
               height: get-vh(76px);
               display: flex;
               flex-direction: column;
               justify-content: space-between;
               align-items: start;
               & > .item {
                  cursor: pointer;
                  font-weight: 600;
                  font-size: get-vh(20px);
                  line-height: 100%;
                  letter-spacing: -0.03em;  
                  & > a {
                     color: #FFFFFF;
                  }
               }
               @media (max-width: 1440px) {
                  width: get-vh(56px);
                  height: get-vh(60px);
                  & > .item {
                     width: 100%;
                     font-weight: 600;
                     font-size: get-vh(16px);
                     line-height: 100%;
                     letter-spacing: -0.03em;  
                     color: #FFFFFF;
                  }
               }
               @media (max-width: 1180px) {
                  width: get-vh(44px);
                  height: get-vh(48px);
                  & > .item {
                     width: 100%;
                     font-weight: 600;
                     font-size: get-vh(13px);
                     line-height: 100%;
                     letter-spacing: -0.03em;  
                     color: #FFFFFF;
                  }
               }
               @media (max-width: 960px) {
                  width: get-vh(35px);
                  height: get-vh(38px);
                  & > .item {
                     width: 100%;
                     font-weight: 600;
                     font-size: get-vh(10.5px);
                     line-height: 100%;
                     letter-spacing: -0.03em;  
                     color: #FFFFFF;
                  }
               }
               @media (max-width: 840px) {
                  width: get-vh(30px);
                  height: get-vh(33px);
                  & > .item {
                     width: 100%;
                     font-weight: 600;
                     font-size: get-vh(9px);
                     line-height: 100%;
                     letter-spacing: -0.03em;  
                     color: #FFFFFF;
                  }
               }
            }
            & > .media {
               width: get-vh(244px);
               height: get-vh(76px);
               display: flex;
               flex-direction: column;
               justify-content: space-between;
               & > .head {
                  font-weight: 600;
                  font-size: get-vh(24px);
                  line-height: 100%;
               }
               & > .social-icons {
                  width: 100%;
                  height: get-vh(36px);
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  & > li {
                     display: inline-block;
                     & > a {
                        height: get-vh(36px);
                        width: get-vh(36px);
                        display: block;
                        overflow: hidden;
                        & > img {
                           display: block;
                           width: 100%;
                           height: 100%;
                           height: auto;
                        }
                     }
                  }
               }
               @media (max-width: 1440px) {
                  width: get-vh(196px);
                  height: get-vh(60px);
                  & > .head {
                     font-weight: 600;
                     font-size: get-vh(19px);
                     line-height: 100%;
                  }
                  & > .social-icons {
                     width: 100%;
                     height: get-vh(28px);
                     display: flex;
                     justify-content: space-between;
                     align-items: center;
                     & > li {
                        display: inline-block;
                        & > a {
                           height: get-vh(28px);
                           width: get-vh(28px);
                           display: block;
                           overflow: hidden;
                           & > img {
                              display: block;
                              width: 100%;
                              height: 100%;
                              height: auto;
                           }
                        }
                     }
                  }
               }
               @media (max-width: 1180px) {
                  width: get-vh(156px);
                  height: get-vh(48px);
                  & > .head {
                     font-weight: 600;
                     font-size: get-vh(15px);
                     line-height: 100%;
                  }
                  & > .social-icons {
                     width: 100%;
                     height: get-vh(22px);
                     display: flex;
                     justify-content: space-between;
                     align-items: center;
                     & > li {
                        display: inline-block;
                        & > a {
                           height: get-vh(22px);
                           width: get-vh(22px);
                           display: block;
                           overflow: hidden;
                           & > img {
                              display: block;
                              width: 100%;
                              height: 100%;
                              height: auto;
                           }
                        }
                     }
                  }
               }
               @media (max-width: 960px) {
                  width: get-vh(126px);
                  height: get-vh(38px);
                  & > .head {
                     font-weight: 600;
                     font-size: get-vh(12px);
                     line-height: 100%;
                  }
                  & > .social-icons {
                     width: 100%;
                     height: get-vh(18px);
                     display: flex;
                     justify-content: space-between;
                     align-items: center;
                     & > li {
                        display: inline-block;
                        & > a {
                           height: get-vh(18px);
                           width: get-vh(18px);
                           display: block;
                           overflow: hidden;
                           & > img {
                              display: block;
                              width: 100%;
                              height: 100%;
                              height: auto;
                           }
                        }
                     }
                  }
               }
               @media (max-width: 840px) {
                  width: get-vh(110px);
                  height: get-vh(33px);
                  & > .head {
                     font-weight: 600;
                     font-size: get-vh(10.5px);
                     line-height: 100%;
                  }
                  & > .social-icons {
                     width: 100%;
                     height: get-vh(18px);
                     display: flex;
                     justify-content: space-between;
                     align-items: center;
                     & > li {
                        display: inline-block;
                        & > a {
                           height: get-vh(15.75px);
                           width: get-vh(15.75px);
                           display: block;
                           overflow: hidden;
                           & > img {
                              display: block;
                              width: 100%;
                              height: 100%;
                              height: auto;
                           }
                        }
                     }
                  }
               }
            }
            & > .mini__textlover {
               margin-top: get-vh(36px);
               width: get-vh(436px);
               height: get-vh(90px);
               font-weight: 600;
               font-size: get-vh(18px);
               line-height: 120%;
               display: flex;
               flex-direction: column;
               align-items: center;
               text-align: center;
               letter-spacing: -0.02em;
               color: rgba(255, 255, 255, 0.6);
               & > span {
                  font-size: get-vh(12px);
                  line-height: 120%;
               }
            }
         }
         @media (max-width: 744px) { 
            position: relative;
            height: auto;
            padding: get-vh(36px) 0;
            width: 100%;
            flex-shrink: 0;
         }
         @media (max-width: 400px) { 
            padding: get-vh(24px) 0;
         }
         & > .foot__containermin {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            & > .logo__text {
               width: get-vh(252px);
               height: get-vh(96px);
               @media (max-width: 400px) { 
                  width: get-vh(168px);
                  height: get-vh(64px);
               }
            }
            & > .few__text {
               margin-top: get-vh(30px);
               width: get-vh(457px);
               display: flex;
               justify-content: space-between;
               font-weight: 600;
               font-size: get-vh(27px);
               line-height: 100%;
               letter-spacing: -0.03em;
               & > .item {
                  & > a {
                     color: #FFFFFF;
                  }
               }
               & > .sep {
                  width: 0px;
                  height: get-vh(27px);
                  border: get-vh(2px) solid #FFFFFF;
                  transform: rotate(-180deg);
               }
               @media (max-width: 400px) { 
                  margin-top: get-vh(20px);
                  width: get-vh(332px);
                  font-size: get-vh(18px);
                  & > .sep {
                     height: get-vh(18px);
                     border: get-vh(1.5px) solid #FFFFFF;
                  }
               }
            }
            & > .media {
               margin-top: get-vh(36px);
               width: 100%;
               height: get-vh(108px);
               display: flex;
               align-items: center;
               justify-content: space-around;
               background: rgba(255, 255, 255, 0.08);
               & > .social-icons {
                  display: flex;
                  gap: get-vh(45px);
                  height: get-vh(54px);
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  & > li {
                     display: inline-block;
                     & > a {
                        height: get-vh(54px);
                        width: get-vh(54px);
                        display: block;
                        overflow: hidden;
                        & > img {
                           display: block;
                           width: 100%;
                           height: 100%;
                           height: auto;
                        }
                     }
                  }
               }
               @media (max-width: 400px) { 
                  margin-top: get-vh(24px);
                  height: get-vh(72px);
                  & > .social-icons {
                     gap: get-vh(36px);
                     height: get-vh(36px);
                     & > li {
                        display: inline-block;
                        & > a {
                           height: get-vh(36px);
                           width: get-vh(36px);
                        }
                     }
                  }
               }
            }
            & > .maney__words {
               margin-top: get-vh(36px);
               width: get-vh(333px);
               height: get-vh(120px);
               display: flex;
               flex-direction: column;
               text-align: center;
               align-items: center;
               justify-content: space-between;
               & > .item {
                  font-weight: 600;
                  font-size: get-vh(24px);
                  line-height: 100%;
                  letter-spacing: -0.03em;
                  color: #FFFFFF;
                  & > a {
                     color: #FFFFFF;
                  }
               }
               @media (max-width: 400px) { 
                  margin-top: get-vh(24px);
                  width: get-vh(222px);
                  height: get-vh(80px);
                  & > .item {
                     font-size: get-vh(16px);
                  }
               }
            }
            & > .mini__textlover {
               text-align: center;
               margin: get-vh(36px) 0;
               width: get-vh(272px);
               font-weight: 600;
               font-size: get-vh(12px);
               line-height: 120%;
               letter-spacing: -0.02em;
               color: rgba(255, 255, 255, 0.6);
               & > span {
                  font-size: get-vh(8px);
                  line-height: 120%;
                  letter-spacing: -0.05em;
               }
               @media (max-width: 400px) { 
                  margin: get-vh(28px) 0;
                  width: get-vh(240px);
                  font-weight: 600;
                  font-size: get-vh(11px);
                  & > span {
                     font-size: get-vh(7px);
                  }
               }
            }
         }
      }
   }
   </style>