<template>
   <div class="first__screens">
      <header>
         <div class="container__hed">
            <div class="logo" @click="$emit('update:current-page', 0); $emit('update:active-document', null); $router.push('/');">
               <img src="../../src/assets/img/logoMini.png" alt="Logo" draggable="false">
            </div>
            <div class="links" v-if="burgerMenu == 0">
               <ul class="page">
                  <li :style="activeHeader === 0 ? 'color: #FFB800;' : 'color: #FFFFFF;'" @click="ClickPage(4)">FAQ</li>
                  <li><a href="https://forum.secondliferp.com.ua/index.php" target="_blank">Форум</a></li>
                  <li :style="activeHeader === 2 ? 'color: #FFB800;' : 'color: #FFFFFF;'" @click="$emit('update:active-document', null); $emit('update:active-header', 1); $router.push('/')">Донат</li>
               </ul>
               <ul class="social-icons">
                  <li><a href="https://discord.gg/TXPnVVbVNm" target="_blank"><img src="../../src/assets/img/discrod.svg" alt="discrod" draggable="false"></a></li>
                  <li><a href="https://t.me/SecondLifeRP" target="_blank"><img src="../../src/assets/img/telegram.svg" alt="telegram" draggable="false"></a></li>
                  <li><a href="https://www.youtube.com/@SecondLifeRP_UA" target="_blank"><img src="../../src/assets/img/youtube.svg" alt="yt" draggable="false"></a></li>
                  <li><a href="https://www.instagram.com/secondliferp_ua" target="_blank"><img src="../../src/assets/img/instagram.svg" alt="inst" draggable="false"></a></li>
                  <li><a href="https://www.tiktok.com/@secondliferp_ua" target="_blank"><img src="../../src/assets/img/tiktok.svg" alt="tiktok" draggable="false"></a></li>
               </ul>
            </div>
            <img src="../assets/img/menu.svg" alt="" class="burger" v-if="burgerMenu == 1" @click="() => {openBurgerMenu = true; burgerMenu = 2}">
            <img src="../assets/img/close.svg" alt="" class="burger" v-if="burgerMenu == 2" @click="() => {openBurgerMenu = false; burgerMenu = 1}">
         </div>
      </header>
      <main>
         <div class="name__document" v-if="activeDocument == 0">Політика конфіденційності</div>
         <div class="name__document" v-if="activeDocument == 1">Користувацька угода</div>
         <div class="name__document" v-if="activeDocument == 2">Правила користування сайтом</div>
         <div class="description__document" v-if="activeDocument == 0">
            &nbsp; &nbsp; &nbsp;У цій Політиці конфіденційності пояснюється, як ФОП Мошенський Костянтин Олегович
            (Правовласник) збирає, використовує і передає особисті дані Користувача у зв’язку з використанням Сайту
            та отриманням Послуг. Правовласник зобов’язується шанобливо ставитися до конфіденційних даних
            Користувача і дотримуємося Закону України «Про персональні дані» (далі – Закон) та положень Загального
            регламенту захисту даних (ЄС) 2016/679 («GDPR»). Незнання умов та термінів встановлених цією
            Політикою, Угодою Користувача та Правил користування не є підставою для пред'явлення з боку
            Користувача будь-яких претензій до Правовласника. <br> <br>
            &nbsp; &nbsp; &nbsp;ІНФОРМАЦІЯ, ЯКУ ЗБИРАЄ ПРАВОВЛАСНИК <br>
            &nbsp; &nbsp; &nbsp;Ця Політика конфіденційності описує методи збору особистої інформації через цей Сайт та Гру для
            можливості надання Послуг. Доступ Користувача до Послуг та всі комунікації між Користуачем та
            Правовласником регулюються цією Політикою конфіденційності та чинним законодавством. Користувач
            можете переглядати цей сайт, не розкриваючи свою особисту інформацію. Однак наші Послуги вимагають
            надання особистої інформації. Надаючи особисту інформацію, Користувач прямо погоджується на збір,
            використання, розкриття та збереження вашої особистої інформації, як описано в цій Політиці, яка
            стосується обробки Правовласником особистих даних Користувача або особистої інформації, яка може
            бути зібрана, коли ви перебуваєте на Сайті та користуєтеся послугами Сайту. <br> <br>
            &nbsp; &nbsp; &nbsp;Тип особистої інформації, яку Правовласник збирає, залежить від того, як Користувач конкретно взаємодіє
            з Правовласником та якими службами користується. Правовласник може обробляти інформацію, що
            міститься в будь-якому запиті, який Користувач надсилає Правовласникові щодо Послуг, включаючи дані
            про поведінку Користувача та взаємодію з Сайтом. <br> <br>
            &nbsp; &nbsp; &nbsp; Дані запиту Користувача можуть оброблятися з метою відповіді на запит Користувача, надання
            Користувачу спеціальної інформації про Послуги, які Правовласник надаємо, розміщення цільової реклами,
            проведення маркетингових досліджень, перевірки ефективності реклами та дослідження схожих аудиторій.
            Юридичною основою для такої обробки є згода, надана там, де здійснюється запит, або особиста згода
            Користувача. <br> <br>
            &nbsp; &nbsp; &nbsp;Правовласник може обробляти інформацію, що міститься або пов’язана з будь-яким повідомленням, яке
            Користувач надсилає Правовласникові, включно з цілями найму. Таке листування можуть включати
            особисті дані Користувача, юридичну інформацію, проекти тощо. Дані листування можуть оброблятися для
            цілей договору між Користувачем та Правовласником та/або вжиття заходів, за запитом Користувача, для
            укладення такого договору. Листування також може регулюватися NDA (угодою про нерозголошення),
            якщо це застосовно. Правовою основою для такої обробки є законні інтереси Правовласника: належне
            адміністрування нашого Сайту та бізнес-процесів, маркетингові та інші комунікації з Користувачами та
            клієнтами. <br> <br>
            &nbsp; &nbsp; &nbsp; Як правило, Правовласник збирає такі категорії особистої інформації: <br>
            − Контактна інформація, така як ім’я, прізвище, номер телефону, ідентифікатор користувача соціальної
            мережі, адреса електронної пошти та подібні контактні дані; <br>
            - Контент користувача, такий як вміст повідомлень, пропозиції, запитання, коментарі, відгуки та інша
            інформація, яку Користувач надсилає Правовласникові, коли звертається до Правовласника та (або)
            отримує Послуги; <br>
            - Інформація про пристрій і браузер, як-от інформація про мережу та з’єднання (включаючи адреси
            Інтернет-провайдера (ISP) та Інтернет-протоколу (IP-адреси), ідентифікатори пристрою та браузера та
            інформація (включаючи пристрій, програму або тип браузера, версію, тип плагіна) і версія, операційної
            системи, налаштування часового поясу та інша технічна інформація, рекламні ідентифікатори,
            ідентифікатори файлів cookie та інформація та подібні дані; <br>
            Правовласник не здійснює навмисну обробку біометричних персональних даних (відомості, що
            характеризують фізіологічні та біологічні особливості людини, на підставі яких можна встановити її
            особистість) та спеціальних категорій персональних даних (що стосуються расової, національної
            приналежності, політичних поглядів, релігійних чи філософських переконань, стану здоров'я, інтимного
            життя). <br> <br>

            &nbsp; &nbsp; &nbsp;Політика Користувача полягає у зберіганні та обробці персональних даних, необхідних для надання
            Користувачу найкращий Послуг. Правовласник не зберігає та не обробляє персональні дані, які не
            використовує. Правовласник зберігає персональні дані до тих пір, поки вони потрібні для цілей,
            викладених нижче: − Персональні дані зберігаються в бухгалтерських документах Правовласника для
            податкових органів щонайменше 10 років після того, як Користувач замовили Послугу; - Особисті дані, які
            Правовласник обробляє для запобігання шахрайству (користувачів, які вчинили), Правовласник зберігає
            щонайменше 5 років після останнього входу Користувача; - В інших випадках дані Користувача
            зберігаються не більше ніж 36 місяців після вашого останнього входу. <br> <br>
            &nbsp; &nbsp; &nbsp;ЯК ПРАВОВЛАСНИК ВИКОРИСТОВУЄ ІНФОРМАЦІЮ <br>
            &nbsp; &nbsp; &nbsp;Правовласник обробляє особисту інформацію про вас на одній або кількох із таких підстав:
            − реєстрація Користувача Правовласником в Грі, надання Користувачеві можливості повноцінного
            використання сервісів Сайту; <br> <br>
            - відображення профілю Користувача на сайті, акаунті Користувача; <br>
            - встановлення та підтримка зв'язку між Користувачем та Правовласником, консультування з питань<br>
            виконання умов Угоди користувача, використання Сайту;
            - для виконання договору, якщо обробка необхідна для виконання контракту;<br>
            - законні інтереси, якщо обробка необхідна для законних інтересів, які переслідує Правовласник або третя
            сторона, за винятком випадків, коли такі інтереси переважають інтереси Користувача або основні права та
            свободи, які вимагають захисту особистої інформації;<br>
            - згода Користувача, якщо Користувач дав згоду Правовласникові на обробку його особистої інформації
            для певних цілей;<br>
            - дотримуватись Закону, якщо обробка необхідна для дотримання вимог законодавства.
            Розповсюдження персональних даних може здійснюватися Правовласником у таких випадках: <br>
            − при обробці персональних даних з метою відображення профілю Користувача для інших Користувачів
            Сайту для підтримки комунікації, у тому числі під час надання послуг дистанційним способом;
            - з метою розміщення відгуків про послуги, що надаються Правовласником, залишені Користувачами, у
            різних джерелах інформації; <br>
            - з метою розміщення відеоматеріалів, отриманих у процесі надання послуг, у різних джерелах інформації;<br>
            - оптимізація Правовласником розсилки повідомлень інформаційного та рекламного характеру;<br>
            - застосування організаційних та технічних заходів щодо забезпечення безпеки персональних даних під час
            їх обробки в інформаційних системах. Знищення персональних даних здійснюється Правовласником у
            таких випадках: − видалення Користувачем персональних даних у відповідному розділі Акаунту
            Користувача; <br>
            - надходження від Користувача відкликання згоди на обробку персональних даних; <br>
            - отримання від Користувача вимоги про знищення персональних даних;<br>
            - закінчення терміну дії згоди;<br>
            - закінчення терміну зберігання персональних даних відповідно до домовленостями Правовласника та
            Користувача. <br>
            Отримання Користувачем Віртуальних предметів може вимагати надання інформацію про платіжні методи
            Користувача, зокрема дані кредитних карток. Правовласник не зберігає та не утримує ці дані, а також не
            обробляємо платежі Користувача. <br> <br>
            &nbsp; &nbsp; &nbsp;Оброблення платежів здійснюється за допомогою платіжної системи Fondy - https://fondy.ua/uk/ та
            постачальниками платіжних послуг, зокрема еквайєрами, емітентами кредитних карток, вашою фінансовою
            установою тощо. Послуги, що надаються Постачальниками платіжних послуг, можуть підлягати нагляду
            фінансових регуляторів і контролюються угодами Постачальників платіжних послуг, що застосовуються до
            транзакції. Правовласник вживає необхідних та достатніх правових, організаційних та технічних заходів
            для захисту інформації, що надається Користувачами, від неправомірного або випадкового доступу,
            знищення, зміни, блокування, копіювання, розповсюдження, а також від інших неправомірних дій з нею. <br><br>
            РОЗТАШУВАННЯ ІНФОРМАЦІЇ<br>
            &nbsp; &nbsp; &nbsp;Правовласник і підконтрольні йому особи знаходяться в Україні. Під час використання Користувача
            нашого Сайту та спілкування Користувача з Правовласником, ваша особиста інформація може бути
            передана за межі країни проживання Користувача та може зберігатися в кількох країнах і мати доступ до
            них. Кожного разу, коли Правовласник передає особисту інформацію в інші юрисдикції, Правовласник
            гарантує, що інформація передається відповідно до цієї Політики конфіденційності. <br><br>
   
            ЗАЯВА ПРО ФАЙЛИ COOKIE <br>
            &nbsp; &nbsp; &nbsp;Файли cookie — це невеликі файли даних, які зберігаються на комп’ютері Користувача або портативному
            пристрої, коли Користувач відвідує певні веб-сторінки, вони використовуються як механізм для того, щоб
            сайт запам’ятовував дії, які браузер виконував у минулому, наприклад натискання певних кнопок або
            попередній вхід. Файли cookie допомагають нам покращити взаємодію з користувачами та дозволяють нам
            персоналізувати наші послуги, вимірювати, які області та функції наших послуг популярні, і розуміти, як
            клієнти використовують послуги. <br><br>
            &nbsp; &nbsp; &nbsp;Необхідні файли cookie (також відомі як суворо необхідні файли cookie): це файли cookie, необхідні для
            роботи сайту Правовласника. До них належать, наприклад, файли cookie, які дають Користувачеві доступ
            до захищених розділів нашого Сайту. Аналітичні/продуктивні файли cookie: вони дозволяють
            Правовласникові розпізнавати та підраховувати кількість відвідувачів і бачити, як відвідувачі
            переміщуються нашим сайтом під час його використання. Це допомагає Правовласникові покращувати
            Сайт, наприклад, гарантуючи, що користувачі можуть легко знайти те, що вони шукають. Функціональні
            файли cookie: вони використовуються для покращення функціональності Сайту та полегшення
            використання Сайту клієнтами. Наприклад, вони використовуються для того, щоб запам’ятати, що
            Користувач раніше відвідував Сайт і попросив залишатися авторизованими на Сайті. Цільові файли cookie:
            ці файли cookie записують відвідування Користувачем сайту Правовласника та ваші звички перегляду,
            наприклад сторінки, які Користувач відвідав, і посилання, за якими Користувач переходив. Вони
            використовуються, щоб показувати Користувачеві рекламу, яка більше відповідає інтересам Користувача, а
            також їх можна використовувати для обмеження кількості переглядів реклами. Вони можуть бути
            розміщені нами або рекламними мережами з нашого дозволу. Зверніть увагу, що ці файли cookie не містять
            особистої інформації Користувача. Правовласник також можемо включати файли cookie третіх сторін на
            наш Сайт, і збір, використання та вибір, пов’язаний із цими файлами cookie третіх сторін, можна знайти на
            таких сторонніх сайтах. Правовласник використовує файли cookie з технічних причин і для персоналізації.
            Деякі з цих файлів cookie можуть бути встановлені під час завантаження сторінки або коли користувач
            виконує певну дію на веб-сайті. Правовласник можемо використовувати файли cookie та пов’язані
            технології, описані нижче, для різних цілей, зокрема для оформлення сайту, менеджер тегів Google,
            реклами, аналітика, соціальних мереж тощо. На наших веб-сайтах ви можете бачити «банер із файлами
            cookie». Якщо Користувач дасте згоду таким чином, Правовласник може встановити рекламні файли cookie
            на веб-сайті. Ми періодично показуватимемо Користувачеві банер, якщо ви передумаєте. <br><br>
            ВАШІ ПРАВА <br>
            &nbsp; &nbsp; &nbsp;Правовласник хотів б переконатися, що Користувач повністю знає всі свої права на захист даних. Кожен
            користувач має право на: Право на виправлення — Користувач маєте право вимагати від Правовласника
            виправлення будь-якої інформації, яку Користувач вважає неточною. Користувач також має право вимагати
            від Правовласника доповнити інформацію, яку Користувач вважає неповною. Право на видалення —
            Користувач має право вимагати видалення його персональних даних за певних умов. Право на обмеження
            обробки — Користувач маєте право вимагати обмеження обробки його персональних даних за певних
            умов. Право на заперечення проти обробки — Користувач має право заперечити проти обробки його
            персональних даних за певних умов. Майте на увазі, що якщо Користувач не дозволе Правовласникові
            збирати його особисту інформацію, ми можемо не мати змоги надавати йосму певні послуги, а деякі наші
            послуги можуть не враховувати інтереси Користувача та вподобання. Користувач завжди можете
            відкликати згоду, отримати або вимагати видалення всіх його особистих даних, які Правовласник має, або
            поставити запитання, надіславши нам електронний запит на secondlifeukraine@gmail.com. <br><br>
            ЗГОДА КОРИСТУВАЧА НА ОБРОБКУ ПЕРСОНАЛЬНИХ ДАНИХ<br>
            &nbsp; &nbsp; &nbsp;Користувач приймає умови Політики надає Правовласнику інформовану та усвідомлену згоду на обробку
            своїх персональних даних на умовах, передбачених Політикою та Законом:border-radius
            − при реєстрації Акаунту Користувача;<br>
            - при внесенні або зміні персональних даних Акаунта Користувача – для персональних даних, які
            Користувач надає під час редагування інформації на Сайті або в Ігри;<br>
            - при заповненні форми зворотного зв'язку;<br>
            - для персональних даних, які автоматично передаються Правовласнику в процесі використання Сайту за
            допомогою встановленого на пристрої Користувача програмного забезпечення.
            Користувач вважається таким, що надав згоду на обробку своїх персональних даних у момент початку
            використання Сайту. Згода Користувача на обробку Правовласником його персональних даних діє з дня
            надання згоди на їх обробку та протягом строку, необхідного для досягнення цілей обробки персональних<br>

            даних. У разі недосягнення Користувачем віку 18 років останній, при наданні своїх персональних даних
            зобов'язаний надіслати на адресу електронної пошти Правовласника, письмову згоду законного
            представника Користувача на обробку таких даних Правовласником. Правовласник, зважаючи на
            автоматизовану систему збору персональних даних, не стежить за наданням такої згоди Користувачем. У
            разі виявлення недієздатності Користувача, який надав свої персональні дані без письмового дозволу його
            законного представника, Правовласник негайно блокує обробку таких персональних даних до з'ясування
            обставин. Правовласник виходить з того, що Користувач надає достовірну та достатню персональну
            інформацію з питань, що пропонуються у формах Сайту, та підтримує цю інформацію в актуальному стані.
            У разі завантаження свого зображення через Акаунт Користувача — Користувач на безоплатній основі дає
            згоду на використання цього зображення (ст. 308 Цивільного кодексу) з метою, не пов'язаною із
            встановленням особи Користувача. Користувач зобов'язується не надавати фотографії третіх осіб як
            зображення Користувача. Користувач усвідомлює, що інформація на Сайті, що розміщується Користувачем
            про себе, може бути доступною для інших Користувачів Сайту, може бути скопійована та поширена
            такими Користувачами у випадках та на умовах, зазначених у п. Політики Користувач ознайомлений із
            цією Політикою, висловлює свою поінформовану та усвідомлену згоду з нею. <br><br>
            &nbsp; &nbsp; &nbsp;Обмеження відповідальності Правовласник не несе відповідальності за можливе нецільове використання
            персональних даних та заподіяння будь-якої шкоди Користувачеві, що сталося внаслідок:
            − технічних неполадок у програмному забезпеченні та в технічних засобах та мережах, що знаходяться поза
            контролем Правовласника; <br><br>
            - у зв'язку з навмисним або ненавмисним використанням Сайту не за їх прямим призначенням третіми
            особами;<br>
            - незабезпечення конфіденційності паролів доступу або навмисної передачі паролів доступу іншої
            інформації з Сайту самим Користувачем при отриманні послуг Правовласника або використанні Сайту
            іншим особам, які не мають доступу до цієї інформації;<br>
            - неправомірних дій третіх осіб щодо доступу до даних Сайту, у т.ч. персональних даних.
            Правовласник не несе відповідальності за порядок використання Персональних даних Користувача третіми
            особами, з якими Користувач взаємодіє у межах використання Сайту. Правовласник не здійснює перевірку
            наявності особливого режиму обробки персональних даних Користувачів. Якщо Користувач є
            громадянином країн Європейського Союзу або громадянином інших держав, які тимчасово або постійно
            проживають на території країн ЄС і отримує доступ до Сайту з країн Європи, Правовласник вживає всіх
            розумних заходів для забезпечення дотримання таких вимог законодавства про захист персональних даних.
            Для цього Користувач зобов'язаний повідомити Правовласника про наявність особливого режиму захисту
            персональних даних шляхом звернення за адресою електронної пошти Правовласника
            secondlifeukraine@gmail.com Зміни до цієї Політики конфіденційності Очікується, що ця Політика про
            конфіденційність час від часу змінюватиметься. Правовласник залишає за собою право вносити зміни до
            Політики конфіденційності у будь-який час, з будь-якої причини та без попередження, крім публікації
            оновленої Політики конфіденційності на нашому Сайті. Правовласник може періодично надсилати
            електронною поштою нагадування про сповіщення та положення та умови, але Користувач має перевіряти
            наш Сайт, щоб переглянути поточну Політику конфіденційності, яка є чинною, і перевірити будь-які зміни,
            які могли бути внесені до неї. Продовження користування Сайтом або його сервісами після публікації нової
            редакції Політики означає ухвалення Політики та її умов Користувачем. У разі незгоди з умовами
            Політики, Користувач повинен негайно припинити використання Сайту та його сервісів. <br><br>
            ЗВ'ЯЖІТЬСЯ З ПРАВОВЛАСНИКОМ <br>
            Користувач має право надсилати Правовласнику свої запити у тому числі щодо використання його
            персональних даних, а також відкликання згоди на обробку персональних даних.
            Електронна пошта для звʼязку:secondlifeukraine@gmail.com <br><br><br><br><br>  
         </div>
         <div class="description__document" v-if="activeDocument == 1"> 
            &nbsp; &nbsp; &nbsp;Ця Угода користувача (далі – «Угода») є договором між Правовласником сайту
            https://secondliferp.com.ua/ та Користувачем, та визначає правила користування Послугами, які включають
            доступ до Гри під назвою «Secondlife», що розміщується на вказаному сайті, розроблена й керується
            ФОП Мошенським Костянтином Олеговичем (ІПН 3814007094, запис в ЄДР про проведення державної
            реєстрації No 2010350000000392919 від 04.09.2024), який зареєстрований в установленому чинним
            законодавством України порядку(далі – «Правовласник»). <br><br>
            &nbsp; &nbsp; &nbsp;Ця Угода набирає чинності після того, як Користувач приймає її умови, тому просимо уважно прочитати їх.
            Користувач не має права використовувати Послуги, якщо не має дієздатності на згоду з умовами цієї Угоди
            відповідно до чинного законодавства.
            Отримуючи доступ до Послуг сайту, завантажуючи чи використовуючи будь-які наші Послуги будь-яким
            чином; отримуючи доступ до платформ, вт.ч. соціальних мереж та (або) форуму, через натискання кнопки
            «Прийняти» або після завершення процесу реєстрації, Користувач підтверджує, що прочитав, зрозумів та
            згоден з усіма положеннями цією Угоди та бере на себе всі зобов'язання, що викладені в цій Угоді,
            Політиці конфіденційності, Правилах користування. Зазначені документи передбачають згоду Користувача
            на обробку, збір, використання і зберігання особистої інформації Користувача. <br><br>
            &nbsp; &nbsp; &nbsp;ЯКЩО КОРИСТУВАЧ НЕ ЗГОДЕН ВЗЯТИ НА СЕБЕ ЗОБОВ'ЯЗАННЯ, ПЕРЕДБАЧЕНІ ЦІЄЮ УГОДОЮ,
            АБО ЯКЩО В БУДЬ-ЯКИЙ МОМЕНТ ЧАСУ УМОВИ УГОДИ СТАНУТЬ ДЛЯ НЕПРИЙНЯТНИМИ ДЛЯ
            КОРИСТУВАЧА, КОРИСТУВАЧ ЗОБОВ’ЯЗАНИЙ ВІДРАЗУ ПРИПИНІТЬ КОРИСТУВАТИСЯ
            ПОСЛУГОЮ. <br><br>
            1. ВИЗНАЧЕННЯ ТЕРМІНІВ<br>
            1.1. «Користувач» — фізична особа, яка за власним бажанням для особистих некомерційних розважальних
            цілей, що не пов'язані зі здійсненням підприємницької діяльності користується Послугами, які надаються
            Правовласником. <br>
            1.2. «Послуга» — ключова частина угоди сторін, яка дозволяє Користувачу через підключення до інтернету
            та Клієнтську частину Ігри виконувати в інтерактивному, розважальному режимі заздалегідь підготовлені
            завдання, проходити сюжет, взаємодіяти з іншими Користувачами, використовуючи ігровий контент,
            оновлення, логіку та програмне забезпечення, яке є результатом діяльності та належать Правовласнику. <br>
            1.3. «Віртуальні товари» — нематеріальні об'єкти, які є частиною Послуги можуть купуватись та
            використовуватися Користувачами Ігри, що являють собою графічний ефект оформлення, за допомогою
            яких визначається, як буде відображено зовнішній вигляд моделі Користувача та інші ігрових предметів під
            час гри. <br>
            1.4. «Ігра» — комп'ютерна програма, що використовує постійне з'єднання інтернетом для організації
            ігрового процесу, зв'язку з іншими Користувачами у віртуальному світі «Secondlife», що включає
            Клієнтську частину Ігри, компоненти, функції, Віртуальні товари, назви, комп'ютерні коди, файли, ігрове
            програмне забезпечення, клієнтське та серверне програмне забезпечення, аудіодоріжки, оновлення,
            картинки, теми, об'єкти,персонажі, імена, слогани, розташування, концепцію, логіку, ілюстрації,малюнки,
            графіку, зображення, відео, анімації, звуки, музику, композиції,звукозаписи, аудіовізуальні ефекти,
            інформацію, дані, документацію, аплети,протоколи чатів, інформація про персонажів, ігровий процес,
            сценарії, записи тощо, включаючи всі доповнення та оновлення до Ігри, а також сервер, як частину
            програмного забезпечення Ігри. <br>
            1.5. «Клієнтська частина» — програмне забезпечення, яке потрібне для участі Користувача в Ігри та має
            бути самостійно встановлене Користувачем на його персональному комп'ютері за допомогою Інтернету. <br>
            1.6. «Сайт» — автоматизована інформаційна система, доступна в мережі Інтернет за адресою
            https://secondliferp.com.ua/, що надає Користувачеві доступ до Послуг та Клієнтської частини. <br>
            1.7. «Акаунт» — обліковий запис внутрі Ігри, де зберігається сукупність даних про Користувача, що
            можуть містити його особисті дані, платіжні реквізити та інші налаштування, що необхідні для його
            розпізнавання та надання доступу до Послуг. <br>
            1.9. «Чіт-код» — це нетипова комбінація даних (послідовність букв, цифр або натискань клавіш),
            програмних чи апаратних процесів або функцій, які здатні надати Користувачеві несправедливу перевагу в

            Грі, змінити хід та механіку роботи або неправомірну модифікувати Послугу, які не недоступні за
            виконання задокументованих Правовласником правил. <br>
            1.9. «Правила користування» — вимоги, що стосуються зовнішнього ставлення для виконання норми
            взаємодії (користування) при отриманні Послуг Користувачем від Правовласника з іншими Користувачами. <br>
            1.10. «Політика конфіденційності» – правила, якими регламентується збір,обробка, використання і захист
            персональних даних, що можуть бути запитанні/отримані у Користувачів. <br><br>
            2. ЗАГАЛЬНІ ПОЛОЖЕННЯ <br>
            2.1. Можливість надання наших Послуг вимагає від Користувача дотримання певних технічних вимог та
            доступу до мережі Інтернет. Використовуючи та отримуючи доступ до Послуг(и), Користувач заявляє, що
            прочитав та повністю прийняв всі вимоги, які зазначені в Угоді та готовий самостійно, власними коштами
            оплачувати програмне забезпечення свого комп'ютера та доступ до мережі Інтернет для отримання Послуг. <br>
            2.2. Для отримання Послуг Користувач має пройти реєстрацію та створити Аккаунт Користувача. Фактом
            та датою укладення та повного й беззаперечного прийняття Угоди є реєстрація Користувача, під час якої
            Користувач погоджується: <br>
            а) з усіма положеннями цієї Угоди. У разі незгоди Користувача з цією Угодою або будь-яким пунктом цієї
            Угоди Користувач не має права отримувати Послуги; <br>
            б) надати правильну, точну, актуальну й повну інформацію про себе відповідно до реєстраційної форми,
            при цьому Користувач розуміє, що Правовласник не зможе надати Послугу Користувачу у разі відсутності
            в нього повної інформації; <br>
            в) своєчасно оновлювати Реєстраційні дані, щоб вони залишалися правильними, точними, актуальними й
            повними;<br>
            г) з тим, що Правовласник залишає за собою право в будь-який час видалити або відкликати будь-яке ім'я
            Користувача з будь-якої причини, що включає, серед іншого, претензії третіх сторін із приводу того, що
            вказане ім'я Користувача порушує права третіх сторін;<br>
            д) якщо Користувач хоче припинити дію Умов, Користувач може зробити це, закривши або ліквідувати
            свій Аккаунт, тим самим припинити використання Послуг. <br>
            2.3. Користувач несе відповідальність за конфіденційність імені Користувача, безпеку комп'ютерної
            системи Користувача та всі дії, які виконуються від Акаунту Користувача. Користувач не може розкривати,
            передавати або іншим чином надавати третім особам право користуватися паролем або Акаунтом
            Користувача, за винятком випадків, дозволених Правовласником. Користувач погоджується відразу
            повідомляти Правовласника про неавторизоване використання паролю або про інше порушення безпеки
            користуючись реквізитами нижче та виходити з Акаунту наприкінці кожної ігрової сесії. <br> 

            2.4. Правовласник не несе відповідальності за використання пароля та Акаунту Користувача або за будь-
            яку взаємодію та діяльність в Ігрі, які стали результатом використання імені Користувача та пароля

            Користувачем чи особами, яким допустили навмисне або по необережності розкрити імені Користувача
            та/абопароля на порушення цього положення про конфіденційність. <br>
            2.5. Акаунт Користувача, включаючи будь-яку інформацію, яка має до нього відношення має суто
            особистий характер. Користувач не має права продавати або надавати за плату право користування своїм
            Акаунтом або іншим чином передавати право користування своїм Акаунтом. <br>
            2.6. Правовласник може змінити, оновити, додати або видалити положення цієї Угоди в будь-який час,
            повідомивши Користувача про такі зміни шляхом розміщення оновленої Угоди на Сайті або, залежно від
            статусу Акаунту Користувача, на розсуд Правовласника, електронною поштою. <br>
            2.7. Ця Угода, а також будь-які зміни до неї, набувають чинності через 30 днів після публікації.
            Використовуючи Послугу після поновлення Угоди Правовласником, Користувач погоджуєтеся з усіма
            оновленими умовами Угоди. У випадку, якщо Користувач не погоджуєтеся з будь-якими оновленими
            умовами Угоди, Користувач має припинити користуватися Послугою. <br>
            2.8. Правовласник зберігає за собою право і без пояснення причини та попередження відмовити будь-якому
            Користувачеві у доступі до Послуг та припинити або призупинити дію Акаунту Користувача, якщо цього
            вимагає закон, Правовласник надішле Користувачеві попереднє повідомлення. <br>

            2.9. Правовласник може призупинити або припинити надання Послуги або змінити контент Послуги чи
            наповнення Сайту або видаляти без будь-яких підстав і без повідомлення будь-який контент чи іншу
            інформацію в т.ч.Акаунт Користувача в будь-який час, за будь-якої причини, з повідомленням або без
            нього. <br>
            2.10. Правовласник може використовувати функції, призначені для виявлення Чіт-кодів. Користувач
            розуміє та погоджується не створювати та не сприяти створенню та використанню Чіт-кодів третіми
            особами. Користувач також погоджується, що не буде прямо чи опосередковано відключати або іншим

            чином перешкоджати роботі програм, що відповідають за виявлення та запобігання використанню Чіт-
            кодів. Користувач погоджуєтесь, що не втручатиметься у роботу Ігри та Послуг, якщо інше не дозволено

            Правовласником та розумієте,що останній має право ліквідувати Акаунт Користувача. <br>
            2.11. Якщо Правовласник припине або призупине Акаунт Користувача, Користувач не матимете доступу до
            Акаунту або будь-чого, пов'язаного з ним. Користувач не матимете права на відшкодування будь-якої
            віртуальної валюти або Віртуальних товарів, отриманих або придбаних під час використання Ігор або
            Послуг. Припинення будь-якої функції Послуги включає припинення доступу до такої функції.
            Припинення надання Послуг призведе до видалення пароля й всієї відповідної інформації, файлів і
            контенту, пов'язаного з Акаунтом Користувача (або будь-якої його частини), включно з Контентом
            Користувача. Правовласник не нестиме ніякої відповідальності за будь-які призупинення або припинення
            дії Послуги, зокрема за видалення Контенту Користувача. Усі положення цієї Угоди, дія та юридична сила
            яких повинна бути збережена виходячи з їх правової природи змісту, залишаться в силі після припинення
            дії Послуги, що включає, серед іншого, положення про право власності, про відмову від гарантій і про
            обмеження відповідальності. <br> <br>
            3. ОФОРМЛЕННЯ ЗАМОВЛЕННЯ <br>
            3.1. Використання Користувачем ігрового сервісу та Послуг здійснюється за ліцензією, а не продається, і ви
            тим самим підтверджуєте, що жодні права власності щодо Ігрового сервісу не передаються та не
            поступаються і ця Угода не повинна тлумачитися як продаж будь-яких прав. <br>
            3.2. Користувач робе замовлення через Сайт і це є згодою на пропозицію Правовласника укласти Угоду на
            певні Послуги. При цьому деякі Послуги можуть бути платними та ціни на них визначаються
            Правовласником самостійно. <br>
            3.3. Коли Користувач розміщує замовлення на Сайті через форму «Донат», Правовласник відправляє
            Користувачеві повідомлення, що підтверджує отримання Користувачем замовлення та містить деталі
            замовлення Користувача. Підтвердженням прийняття Користувачем пропозиції про укладання Угоди слід
            вважати те, що Правовласник списує відповідну ціну з Користувача з узгодженого засобу оплати та робить
            відповідний контент та Послуги доступними для Користувача. Користувач погоджуєтесь на отримання
            рахунків-фактур в електронному вигляді. <br>
            3.4. Користувач несе відповідальність за достовірність наданої інформації при оформленні Замовлення.
            Правовласник має право відмовитися від передання замовлення Користувачеві у будь-якому випадку, в
            тому числі якщо відомості,вказані Користувачем під час оформлення замовлення, є неповними або
            викликають підозру щодо їх дійсності. <br>
            3.5. Якщо будь-якій зі сторін Угоди необхідна додаткова інформація, вона має право запросити її в іншої
            сторони. У разі ненадання необхідної інформації Користувачем, Правовласник не несе відповідальності за
            надання якісної Послуги Користувачу. <br>
            3.6. Оформлення замовлення є додатковим підтвердженням Користувача з усіма умовами цієї Угоди та
            наданням дозволу на збір, обробку та передачу персональних даних. Усі платежі за Послуги, що
            здійснюються можуть здійснюватися та оброблятися через відповідну платформу третьої сторони. У цьому
            відношенні застосовуються умови використання та оплати сторонньої платформи, доповнені цією Угодою.
            Більше про Користувача і персональні дані в Політиках конфіденційності. <br>
            3.7. Усі віртуальні валюти та Віртуальні товари вважаються остаточними й не підлягають поверненню,
            якщо на розсуд Правовласника не було вирішено інакше. Користувач не матимете ніякого права, зокрема
            права власності на будь-які Віртуальні товари та/або віртуальні валюти, які Користувач отримує.
            Віртуальна валюта може бути використана виключно для отримання Віртуальних товарів.Віртуальна

            валюта й Віртуальні товари не є законними платіжними засобами не можуть бути перепродані, передані за
            вартістю, виплачені грошовими коштами, куплені не через Послугу, а іншими засобами чи методами, які
            непередбачені в Грі, або застосовані до будь-якого іншого Акаунту, за винятком випадків, описаних тут,
            або відповідно до вимог чинного законодавства. <br>
            3.8. Віртуальна валюта та Віртуальні товари не мають встановленого терміну дії та не мають матеріальної
            цінності у реальному світі, при цьому Правовласник має повний контроль над всією віртуальною валютою
            й Віртуальними товарами та в будь-який час має право в односторонньому порядку змінити цінність,
            змінити та/або усунути будь-яку віртуальну валюту й Віртуальні товари на свій розсуд і без будь-якої
            відповідальності. Зобов'язання Правовласника Користувачем віртуальних послуг та/або Віртуальної валюти
            на Акаунт Користувача. <br>
            3.9. Правовласник не надаватиме жодних відшкодувань. Віртуальна валюта й Віртуальні товари не мають
            жодної реальної валютної цінності, не можуть обмінюватися на реальні гроші й можуть використовуватися
            тільки в Грі. Користувачу надається обмежена, відклична, непередавана ліцензія на використання
            Віртуальної валюти або Віртуальних товарів у Грі. Крім цієї ліцензії, у вас немає правових підстав або прав
            на вищезгадану валюту й товари. <br>
            3.10. Якщо Акаунт Користувача було закрито або призупинено з будь-якої причини (на розсуд
            Правовласника або якщо Правовласник припинить підтримку Гри), витрачаєте всю валюту й Віртуальні
            товари. Правовласник має абсолютне право керувати, регулювати, контролювати, змінювати та/або
            анулювати віртуальну валюту та/або Віртуальні товари, як вважатиме за необхідне на власний розсуд, і не
            несе обов'язки перед вами або іншими особами за подібні дії. <br>
            3.11. Оброблення платежів може залежати від виду платіжного методу, який ви використовуєте, при цьому
            можуть існувати певні методи оплати, неприйнятні для постачальників платіжних послуг або на які можуть
            поширюватися обмеження, що накладаються ними (включно з обмеженнями, що накладаються на
            використання певних видів кредитних карток, дебетових карток, карток певних виробників), або різні
            обмеження транзакцій у певних валютах. У максимальній мірі, дозволеній законом, ми не несемо
            відповідальності за будь-які платежі, які ви здійснюєте, а також за якість роботи будь-якого з
            постачальників платіжних послуг. Проводячи транзакцію, ви приймаєте умови угоди або будь-які
            обмеження, які можуть застосовуватись постачальниками платіжних послуг. <br>
            3.12. Користувач погоджується оплачувати будь-які комісійні збори й чинні податки, які поширюються на
            Користувача або на будь-яку особу, що використовує зареєстрований на Користувача Аккаунт. Будь-які
            додаткові витрати або зобов'язання, які накладаються на вас під час взаємодії з третіми сторонами, є
            відповідальністю Користувача. Збори не підлягають поверненню. <br>
            3.13. Надаючи Правовласнику або одному з її платіжних операторів платіжну інформацію, Користувач
            гарантує, що є законним власником картки, ПІН-коду та облікового запису, пов'язаних з цим платежем.
            Користувач погоджуєтесь не використовувати IP-проксі або інші методи, що дозволяють приховати місце
            проживання Користувача, щоб обійти географічні обмеження на ігровий контент. В іншому випадку
            Правовласник може блокувати Користувача доступ до Аккаунту. <br>
            3.14. Користувач має право на повернення коштів, якщо замовлення на Послугу прийшло помилково або в
            неправильному форматі такі як (поповнення на помилкову суму, збій у системі тощо). <br>
            4. ІНТЕЛЕКТУАЛЬНА ВЛАСНІСТЬ І ЛІЦЕНЗІЯ <br> <br>
            4.1. Ви визнаєте та погоджуєтеся з тим, що всі права інтелектуальної власності на наші Послуги й усі
            супутні технології, а також інформація та контент, доступні через Послуги, включно з персонажами,
            зображенням, сюжетними лініями, звуками, віртуальною валютою та Віртуальними товарами в Грі,
            належать Правовласнику. <br>
            4.2. Користувач також визнає та погоджується з тим, що не має права власності чи будь-яких інших
            майнових прав на Акаунт і що всі права на Акаунт належать Правовласнику. <br>
            4.3. Для використання Гри потрібно зайти на Сайт, ввести адресу серверу Правовласника, після чого
            автоматично розпочинається скачування. Цим Правовласник передає Користувачеві, а Користувач приймає
            обмежену, невиключну, відкличну ліцензію (що підлягає наведеним у цьому документі обмеженням), яка

            не підлягає передачі й не передбачає видачу субліцензій. Ця ліцензія дозволяє
            завантажувати,встановлювати, здійснювати доступ та використовувати Послуги виключно для особистих
            некомерційних розважальних цілей. Будь-яке інше використання недозволено. <br>
            4.4. Користувач не можете іншим чином використовувати, завантажувати, друкувати, відображати,
            відтворювати, публікувати, ліцензувати, розміщувати, передавати, змінювати, створювати похідні роботи,
            модифікувати, перекладати перепроєктовувати, розбирати, адаптувати, поширювати, продавати, купувати
            або використовувати Послугу, її функції або компоненти повністю або частково без нашого попереднього
            письмового дозволу. <br>
            4.5. Користувач не може продавати, копіювати, обмінювати, відчужувати, передавати або іншим чином
            поширювати все, що Користувач скопіював або купив у Грі, якщо це явно не дозволено Правовласником,
            який залишає за собою всі права, ненадані цією Угодою. Ця Угода не містить і не надає інших ліцензій,
            крім прямо передбачених Угодою. <br>
            4.6. З причин, що включають, крім іншого, безпеку системи, стабільність розраховану на багато
            Користувачів сумісність, Правовласнику може знадобитися виконання автоматичного оновлення,
            попереднього завантаження, створення нових версій або іншого поліпшення Послуг, і, відповідно, системні
            вимоги для використання Контенту та Послуг можуть бути часом змінюватись. Користувач погоджуєтесь
            на таке автоматичне оновлення. Ви розумієте, що ця Угода не дає Користувачеві права на майбутні
            оновлення, нові версії або інші вдосконалення Послуг. <br>
            4.7. Правовласник поважає права інших на інтелектуальну власність і закликає до цього всіх, хто
            користується Сайтом, Грою та Послугами. Якщо Користувач вважаєте, що використання Користувачем
            роботи в рамках Ігри порушує авторські права Користувача, то Користувач можете повідомити
            Правовласника про це, використовуючи реквізити нижче. <br><br>
            5. КОНТЕНТ КОРИСТУВАЧА<br>
            5.1. Користувач погоджується, що Користувач публікує контент при отриманні Послуг за власним
            бажанням. Будь-які дані, текст, інформація, файли, графіка,фотографії та їх добірки, які завантажені,
            розміщені, відправлені електронною поштою або іншим чином опубліковані вами через Послугу,
            підлягають необмеженому комерційному, некомерційному та/або рекламному використанню
            Правовласником (як частково, так і повністю). <br>
            5.2. Тільки Користувачі несуть повну відповідальність за користувацький контент. Правовласник може
            контролювати, а може і не контролювати контент Користувача і не гарантує точність, якість або цілісність
            будь-якого контенту Користувача, який опубліковано в рамках користування Послугою, Використовуючи
            Послугу, Користувач визнає і погоджується, що Користувач може зіткнутися з матеріалом, який
            Користувач вважає образливим або небажаним. <br>

            5.3. Користувач погоджується, що Правовласник ні за яких обставин не нестиме відповідальності за будь-
            який контент Користувача, що включає, серед іншого, помилки в контенті Користувача або будь-які втрати

            або збитки, завдані підчас використання контенту Користувача. Правовласник не має зобов'язань і не буде
            перевіряти будь-який контент Користувача, але має право на свій розсуд видаляти будь-який контент
            Користувача в разі скарги. Якщо цього вимагає закон, Правовласник повідомить вас до або після видалення
            контенту Користувача, який ви опублікували або завантажили. <br>
            5.4. Приймаючи умови цієї Угоди, Користувач надаєте та гарантуєте Правовласнику безвідкличну,
            безстрокову, передавану, не ексклюзивну, безоплатну,територіально не обмежену ліцензію (зокрема право
            на субліцензії й передачу третім особам), а також право копіювати, відтворювати,
            виправляти,підлаштовувати, змінювати, створювати похідні роботи, випускати, запускати в серійне
            виробництво, публікувати, поширювати, продавати, ліцензувати,встановлювати права, переносити, давати
            у тимчасове користування,передавати, публічно надавати, публічно виконувати чи надавати електронний
            доступ, транслювати, публічно повідомляти за допомогою телекомунікацій, показувати, виконувати,
            вводити в пам'ять комп'ютера, атакож будь-яким способом використовувати й застосовувати Користувача
            Контент, а також всі змінені та похідні продукти Користувачаого Контенту в зв'язку з наданням Послуги,
            зокрема для маркетингу і реклами Послуги. Ця ліцензія надається на строк дії ліцензованих прав або, якщо
            це дозволено законом, безстроково. За винятком випадків, заборонених законодавством, ви відмовляєтеся
            від будь-яких прав на згадку імені автора та/або будь-яких моральних прав, які виможете мати щодо

            Користувачаого Контенту, незалежно від того, чи змінювався Користувача Контент якимось чином.
            Користувач гарантує та заявляє, що Користувач маєте право завантажувати будь-який контент, який
            Користувач робе доступним (публікує) через Послугу. <br><br>
            6. ІНШІ УМОВИ<br>
            6.1. Усі спори, що виникають між Користувачем і Правовласником,вирішуються шляхом переговорів. У
            випадку недосягнення врегулювання спірного питання шляхом переговорів, Користувач та/або
            Правовласник мають право звернутися за вирішенням спору до судових органів відповідно до чинного
            законодавства України. <br>
            6.2. Якщо інше не обумовлюється в цій Угоді у прямій формі, якщо будь-яке положення цієї Угоди буде в
            судовому порядку незаконним або нездійсненним, воно повинно виконуватися в максимально допустимій
            мірі, а інші положення цієї Угоди повинні залишатися чинними на повну силу. <br>
            6.3. Користувач погоджується з тим, що Правовласник має право надсилати повідомлення (у тому числі за
            допомогою електронної пошти, смс і інших засобів звʼязку), які містять рекламну інформацію про Сервіс,
            Гра або матеріали, що тематично пов'язані з ними. <br><br>
            7. КОНТАКТНА ІНФОРМАЦІЯ<br>
            7.1. Якщо у Користувача виникли будь-які питання за цією Угодою, будь ласка, зв'яжіться з службою
            підтримки Правовласника.
            Правовласник зробе все можливе, щоб допомогти Користувачеві.
            Контакт технічної підтримки: t.me/SecondlifeSupport
            Електронна адреса: secondlifeukraine@gmail.com <br><br><br><br>
         </div>
         <div class="description__document" v-if="activeDocument == 2">
            &nbsp; &nbsp; &nbsp;Поведінка Користувача в мережі та взаємини з іншими Користувачами повинні відповідати цим
            Правилам користування, які встановлюють норми та вимоги, для координації поведінкової діяльності людей та
            поліпшення якості Послуг. Користувач має право настроїти Акаунт, змінювати ім'я користувача та паролі для
            доступу до нього; редагувати свій Акаунт та інформацію у профілі Користувача про себе; отримувати Послуги
            та здійснювати інші, не заборонені законом, Правилами та Угодою, дії щодо використання Сайту, Гри та
            Послуг. Отримуючи Послуги від Правовласника, ви погоджуєтесь дотримуватися наведених нижче правил і не
            спонукати інших осіб до їх порушення: <br><br>
            − не створювати Акаунт із використанням неправдивої інформації;<br>
            − не створювати Акаунт або не використовувати Послугу, якщо раніше Акаунт Користувача було вилучено
            Правовласником або Користувачеві було заборонено грати в Ігру;<br>

            − не використовувати Акаунт Користувача, Сайт або Послугу для реклами або нав'язування й передачі будь-
            яких комерційних рекламних оголошень, зокрема розсилок, небажаної пошти або повторюваних повідомлень

            будь-якій особі;<br>
            − не використовувати свій Акаунт або Послугу, щоб займатися будь-якою незаконною діяльністю;<br>
            − не здійснювати доступ до облікового запису іншого Користувача без його дозволу;<br>
            − не використовувати Сайт або Послугу будь-яким чином, що порушує чинне законодавство або положення чи є
            забороненим згідно з цією Угодою;<br>
            − не продавати, не купувати й не використовувати у комерційних цілях будь-які компоненти Послуг, які
            включаються Сервером;<br>
            − не ображати, домагатися, переслідувати, загрожувати чи іншим чином порушувати законні права (такі, як
            право на невтручання в особисте життя та гласність) інших Користувачів;<br>
            − не використовувати можливості Сайту або Послуги для будь-яких інших цілей, не пов'язаних із їх прямим
            призначенням;<br>
            − не змінювати або не відключати будь-які функції, пов'язані з безпекою Сайту або Послуг чи їх складових,
            зокрема Послуги, що надаються на Сторонніх платформах;<br>
            − не завантажувати або надавати іншим чином файли, які містять зображення, фотографії, програмне
            забезпечення або інші матеріали, захищені законами про інтелектуальну власність, включаючи, зокрема, закон
            про авторське право або торгові марки (або право на невтручання в особисте життя та гласність), за винятком
            випадків, коли ви є правовласником або отримали всі необхідні дозволи;<br>
            − не завантажувати (крім кеша відображення сторінки) будь-яку частину Гри, що належать Правовласнику, або
            будь-яку інформацію, що міститься в них, за винятком випадків, явно передбачених Правовласником;<br>
            − забороняється копіювати, перекладати, публікувати, декомпілювати, виконувати технічний аналіз,
            дизасемблювати або конвертувати Сайт або здійснювати спробу отримати доступ до вихідного коду Сайту для
            створення похідних робіт на основі вихідного коду Сайту чи іншим чином;<br>
            − забороняється завантажувати, зберігати, публікувати, розповсюджувати, надавати доступ або іншим чином
            використовувати будь-яку інформацію, інструменти та (або) матеріали, які пропагують і (або) розпалюють
            расову, релігійну, етнічну ненависть чи ворожнечу, або захищають ідеологію фашизму, комунізму чи расової
            переваги, містять негативні заяви про будь-яку країну, народ, релігію, політику, принижують національну
            гідність, у тому числі за ознакою національного походження політичних переконань;
            сприяють злочинній діяльності або містять поради, інструкції чи вказівки щодо скоєння злочинних дій;містять
            особисті дані, зображення третіх осіб без їхньої попередньої згоди, за винятком випадків, передбачених
            законодавством;<br>
            − не завантажувати файли, які містять віруси, троянські програми, хробаків або будь-яке подібне програмне
            забезпечення, яке може порушити роботу інших комп'ютерів або пошкодити власність інших осіб;<br>
            − не здійснювати атаки на сервери Гри або в інший спосіб порушувати його роботу;<br>
            − крім того, ви гарантуєте, що ви не перебуваєте в країні, яка визнана Україною як державою-спонсором
            тероризм або країною що надає підтримку тероризму;<br>
            − не обмежувати або не перешкоджати іншим Користувачам отримувати Послуги, програмне забезпечення або
            інший контент;<br>
            − не здійснювати будь-які інші протизаконні дії. <br>
            Тільки Користувач несе відповідальність за його взаємодію з іншими Користувачами Послуг. Правовласник
            може в будь-який час втрутитися в суперечки між Користувачем та іншими Користувачами, але не
            зобов'язується робити цього. Правовласник не нестиме відповідальності за будь-який результат цих суперечок,
            зокрема позови й компенсації будь-якого характеру. <br>
            У максимальній мірі, дозволеній законом, Правовласник залишає за собою право вирішувати, яка поведінка є
            порушенням правил Угоди та Правил користування. У разі порушення вищевказаних положень, Правовласник
            має право на свій розсуд вжити або не вжити дії, що включають, крім іншого, видалення вашого Акаунт або
            заборону на користування Послугами. <br> <br><br><br>
         </div>
      </main>
      <div class="open__burgermenu" v-show="openBurgerMenu">
         <div class="list__social">
            <a href="#Faq" class="item" @click="() => {{$emit('update:active-document', null); $emit('update:current-page', 4); $emit('update:open-burgerMenu', false); openBurgerMenu = false; $router.push('/')}}">FAQ</a>
            <div class="item"><a href="https://forum.secondliferp.com.ua/index.php" target="_blank">Форум</a></div>
            <div class="item"  @click="$emit('update:active-document', null); $emit('update:active-header', 1); $router.push('/')">Донат</div>
            <a href="https://www.youtube.com/@SecondLifeRP_UA" class="item"><img src="../assets/img/youtubeP.svg" alt="" class="icon"> YouTube</a>
            <a href="https://www.tiktok.com/@secondliferp_ua" class="item"><img src="../assets/img/tiktokP.svg" alt="" class="icon"> TikTok</a>
            <a href="https://www.instagram.com/secondliferp_ua" class="item"><img src="../assets/img/instagramP.svg" alt="" class="icon"> Instagram</a>
            <a href="https://discord.gg/TXPnVVbVNm" class="item"><img src="../assets/img/discrodP.svg" alt="" class="icon"> Discord</a>
            <a href="https://t.me/SecondLifeRP" class="item"><img src="../assets/img/telegramP.svg" alt="" class="icon"> Telegram</a>
         </div>
      </div>
   </div>
 </template>

<script>
export default {
   name: "DocumentPage",
   props: {
      activeDocument: {
         type: Number,
         required: true,
      },
      historyPosition: {
         type: Number,
         required: true,
      }
   },
   data() {
      return {
         burgerMenu: 0,
         openBurgerMenu: false,
      };
   },
   methods: {
      ClickPage(page) {
         this.$emit('update:active-document', null); 
         this.$router.push('/');
         this.$emit('update:current-page', 4); 
         let position = this.historyPosition;
         if (this.openBurgerMenu == false) {
            setTimeout(() => {
               const anim = document.querySelector('.container').animate(
               [
                  { transform: `translateY(${position}vh)`},
                  { transform: `translateY(${-100 * page}vh)`},
               ], 
               {
                  duration: 800,
                  iterations: 1,
                  easing: 'ease-in-out',
                  effect: 'spring',
                  spring: { mass: 1, stiffness: 320, damping: 40 },
               }
               )
               anim.addEventListener('finish', () => {
                  position = -100 * page
                  this.$emit("update:history-position", position);
                  document.querySelector('.container').style.transform = `translateY(${position}vh)`;
               })
            },250)
         } else {
            this.openBurgerMenu = false; 
            setTimeout(() => {
               const anim = document.querySelector('.container').animate(
               [
                  { transform: `translateY(${position}vh)`},
                  { transform: `translateY(${-100 * page}vh)`},
               ], 
               {
                  duration: 800,
                  iterations: 1,
                  easing: 'ease-in-out',
                  effect: 'spring',
                  spring: { mass: 1, stiffness: 320, damping: 40 },
               }
               )
               anim.addEventListener('finish', () => {
                  position = -100 * page
                  this.$emit("update:history-position", position);
                  document.querySelector('.container').style.transform = `translateY(${position}vh)`;
               })
            }, 150)
         }
      },
      WidthUpdate() {
         if (window.innerWidth <= 744) this.burgerMenu = 1;
         else this.burgerMenu = 0;
      },
   },
   created() {
      window.addEventListener("resize", this.WidthUpdate);
   },
   mounted() {
      this.WidthUpdate();
   },
};
</script>

<style lang="scss">
@import './nulstyle.scss';
@import './variables.scss';
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
$mouse-device: '(hover: hover) and (pointer: fine)';
.first__screens {
   height: 100vh;
   width: 100vw;
   position: absolute;
   top: 0;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background: url(../assets/img/back/slide5.jpg) no-repeat center center;
   background-size: cover;
   @media (max-width: 744px) {
      background: url(../assets/img/mobile/back/slide1.jpg) no-repeat center center;
      background-size: cover;
      overflow-x: hidden;
   }
   & > header {
      width: 100%;
      height: get-vh(160px);
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100vw - #{get-vh(128px)});
      @media (max-width: 400px) { 
         width: calc(100vw - #{get-vh(64px)});
      }
      & > .container__hed {
         z-index: 2;
         height: 100%;
         width: get-vh(1680px);
         display: flex;
         align-items: center;
         justify-content: space-between;
         @media (max-width: 1440px) { 
            width: get-vh(1280px);    
         }
         & > .logo img {
            position: relative;
            width: get-vh(240px);
            height: get-vh(88px);
            cursor: pointer;
            @media (max-width: 1440px) { 
               width: get-vh(192px);
               height: get-vh(70px);   
            }
         }
         & > .links {
            margin-left: get-vh(10px);
            flex: 1;
            max-width: get-vh(577px);
            height: get-vh(36px);
            display: flex;
            justify-content: space-between;
            @media (max-width: 1440px) { 
               max-width: get-vh(461px);
               height: get-vh(28px);
            }
            & > .page {
               width: 50%;
               height: 100%;
               display: flex;
               justify-content: space-between;
               font-weight: 600;
               font-size: get-vh(24px);
               line-height: 125%;
               letter-spacing: -0.03em;
               text-shadow: 0px 0px get-vh(8px) rgba(0, 0, 0, 0.25);
               cursor: pointer;
               & > li > a {
                  color: #FFFFFF;
               }
               @media (max-width: 1440px) { 
                  font-size: get-vh(19px);
               }
            }
            & > .social-icons {
               width: 42%;
               height: get-vh(36px);
               display: flex;
               align-items: center;
               justify-content: space-between;
               @media (max-width: 1440px) { 
                  height: 100%;
               }
               & > li {
                  display: inline-block;
                  & > a {
                     box-shadow: 0px 0px get-vh(8px) rgba(0, 0, 0, 0.25);
                     border-radius: 50%;
                     height: get-vh(36px);
                     width: get-vh(36px);
                     display: block;
                     overflow: hidden;
                     @media (max-width: 1440px) { 
                        height: 100%;
                        width: get-vh(28px);
                     }
                     & > img {
                        display: block;
                        width: 100%;
                        height: 100%;
                     }
                  }
               }
            }
         }
         & > .burger {
            width: get-vh(72px);
            height: get-vh(72px);
            position: relative;
            z-index: 2;
         }
      }
   }
   & > main {
      flex: 1 1 auto;
      max-width: get-vh(1920px);
      width: calc(100vw - #{get-vh(192px)});
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 400px) { 
         width: calc(100vw - #{get-vh(48px)});
      }
      & > .name__document {
         font-size: get-vh(64px);
         font-weight: 600;
         line-height: 1.1;
         letter-spacing: -0.96px;
         text-align: center;
         @media (max-width: 1280px) { 
            font-size: get-vh(51px);
         }
         @media (max-width: 1280px) { 
            font-size: get-vh(51px);
         }
         @media (max-width: 744px) { 
            font-size: get-vh(46px);
         }
         @media (max-width: 400px) { 
            font-size: get-vh(40px);
         }
      }
      & > .description__document {
         margin-top: get-vh(36px);
         max-width: get-vh(1600px);
         height: get-vh(790px);
         overflow-y: scroll;

         text-shadow: 0px 0px get-vh(24px) rgba(0, 0, 0, 0.35);
         font-family: Montserrat;
         font-size: get-vh(20px);
         font-style: normal;
         font-weight: 600;
         line-height: 125%; /* 25px */
         letter-spacing: -0.4px;

         mask-image: linear-gradient(180deg, #000 90.65%, rgba(0, 0, 0, 0.00) 100%);

         &::-webkit-scrollbar {
            width: 0;
         }
      }
   }
   & > .open__burgermenu {
      width: 100%;
      height: 100%;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      backdrop-filter: blur(get-vh(12px));
      background: rgba(0, 0, 0, 0.5);
      animation: openstart .5s ease-out;
      @keyframes openstart {
         0% {
            background: rgba(0, 0, 0, 0);
            backdrop-filter: blur(get-vh(0px));
         }
         100% {
            background: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(get-vh(12px));
         }
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: get-vh(40px);
      & > .list__social {
         height: 100%;
         width: get-vh(275px);
         margin-top: get-vh(124px);
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         align-items: center;
         padding: get-vh(93px) 0;
         & > .item {
            font-weight: 600;
            font-size: get-vh(42px);
            line-height: 125%;
            letter-spacing: -0.03em;
            color: #FFFFFF;
            & > a {
               color: #FFFFFF;
            }
            &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
               line-height: get-vh(48px);
               align-items: center;
               & > .icon {
                  width: get-vh(48px);
                  height: get-vh(48px);
               }
            }
         }
      }
   }
}
</style>